.summary-alarm {
  text-decoration: underline;
}

.dot {
  height: 10px;
  width: 10px;
  border-radius: 50%;
  display: block;
  float: left;
}
.dot-success {
  background-color: #449d44;
}
.dot-error {
  background-color: #d9534f;
}
.dot-blue {
  background-color: #0000FF;
}
.dot-yellow {
  background-color: #FFBF00;
}
.circuit-card-holder {
  width: 100%;
  padding: 0 10px;
}
