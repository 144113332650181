.msp-login {
  /* Hiding social media login, new account creation link, and dividers */
  /* If IE */ }

@font-face {
  .msp-login {
    font-family: 'Avenir';
    src: url(https://msplogin.mydigitalservices.com/fonts/Avenir.tcc) format("opentype");
    font-weight: 400;
    font-style: normal; } }
  .msp-login html, .msp-login body {
    font-family: arial, sans-serif;
    font-size: 12px;
    color: #555;
    background: RGBA(69, 88, 110, 0.05) url(https://msplogin.mydigitalservices.com/images/clouds.67c959c6.jpg);
    background-position: center;
    background-size: 100% 100%;
    background-repeat: no-repeat;
    min-height: 100vh;
    overflow: hidden;
    /*background: url('image.png') no-repeat center fixed;
        background-size: cover;    */ }
  @media (max-height: 600px) {
    .msp-login html, .msp-login body {
      overflow: visible; } }
  .msp-login h1, .msp-login h2, .msp-login h3, .msp-login h4, .msp-login h5, .msp-login h6 {
    font-weight: normal; }
  .msp-login .red {
    color: #da5a5a; }
  .msp-login a, .msp-login button {
    color: #6c8bad;
    -webkit-transition: all 1000ms ease;
    -moz-transition: all 1000ms ease;
    -ms-transition: all 1000ms ease;
    -o-transition: all 1000ms ease;
    transition: all 1000ms ease;
    cursor: pointer; }
  .msp-login button {
    background: #fff;
    border: 1px solid #c7d9e6;
    min-width: 80px;
    border-radius: 25px;
    text-align: center;
    margin: 8px 3px 0;
    white-space: nowrap; }
  .msp-login #next:hover {
    background: RGBA(109, 139, 172, 0.1); }
  .msp-login a.btn.orange {
    background: #ff9e15;
    border-color: #ff9e15;
    color: #fff; }
  .msp-login a.btn.orange:hover {
    background: RGBA(253, 157, 47, 0.7); }
  .msp-login a.btn.blue {
    background: #00b5e1;
    border-color: #00b5e1;
    color: #fff; }
  .msp-login a.btn.blue:hover {
    background: RGBA(30, 182, 223, 0.7); }
  .msp-login a.btn.green {
    background: #c3d500;
    border-color: #c3d500;
    color: #fff; }
  .msp-login a.btn.green:hover {
    background: RGBA(195, 211, 44, 0.7); }
  .msp-login a.btn.nuetral {
    background: #6c8bad;
    border-color: #6c8bad;
    color: #fff; }
  .msp-login a.btn.nuetral:hover {
    background: RGBA(109, 139, 172, 0.7); }
  .msp-login a.no-btn {
    background: transparent;
    color: #6c8bad;
    padding: 2px 0;
    border-bottom: 2px solid #c7d9e6;
    border-radius: 0;
    white-space: nowrap; }
  .msp-login a.no-btn:hover {
    padding: 2px 6px; }
  .msp-login a:hover, .msp-login a:active, .msp-login a:focus, .msp-login a:visited {
    text-decoration: none;
    outline: 0; }
  .msp-login .table {
    border-top: none;
    border-bottom: 1px solid #eee;
    margin-bottom: 0;
    background: #fff; }
  .msp-login .table.table-striped tbody tr:nth-of-type(odd) {
    background-color: rgba(0, 0, 0, 0.08); }
  .msp-login .table .check {
    width: 30px; }
  .msp-login .table .check input {
    margin: 0 auto;
    display: table; }
  .msp-login .table .btn {
    margin: 0; }
  .msp-login .table .no-btn:hover {
    padding: 2px 0; }
  .msp-login .table > thead > tr > th, .msp-login .table > tbody > tr > th {
    border-top: none;
    white-space: nowrap; }
  .msp-login .table > thead > tr > th, .msp-login .table > tbody > tr > th, .msp-login .table > tfoot > tr > th, .msp-login .table > thead > tr > td, .msp-login .table > tbody > tr > td, .msp-login .table > tfoot > tr > td {
    vertical-align: middle; }
  .msp-login .halves .half {
    float: left;
    width: 50%; }
  .msp-login .halves .half:first-child {
    padding-right: 10px; }
  .msp-login .halves .half:last-child {
    padding-left: 10px; }
  .msp-login .login {
    padding-top: 90px;
    /* */
    width: 100%;
    text-align: center; }
  .msp-login .login img {
    width: 240px;
    max-width: 100%;
    display: table;
    margin: 30px auto 60px; }
  @media (max-width: 767px) {
    .msp-login html, .msp-login body {
      overflow: visible; }
    .msp-login .login {
      /*margin-top: 0;*/
      /* SB */
      width: 100%; } }
  .msp-login .login .form-holder {
    display: inline-block;
    text-align: left;
    position: relative;
    background: #fff;
    margin: 0 auto;
    border-radius: 10px;
    padding: 0 28px 28px;
    -webkit-box-shadow: 0px 5px 10px -2px RGBA(0, 0, 0, 0.1);
    -moz-box-shadow: 0px 5px 10px -2px RGBA(0, 0, 0, 0.1);
    -o-box-shadow: 0px 5px 10px -2px RGBA(0, 0, 0, 0.1);
    -ms-box-shadow: 0px 5px 10px -2px RGBA(0, 0, 0, 0.1);
    box-shadow: 0px 5px 10px -2px RGBA(0, 0, 0, 0.1); }
  .msp-login .login .localAccount {
    padding: 0 15px 15px;
    display: block; }
  .msp-login .login .form-holder h2 {
    font-size: 24px;
    text-align: center;
    margin-top: 0; }
  .msp-login .login .localAccount button {
    display: block;
    font-size: 16px;
    padding: 12px;
    width: 100%; }
  .msp-login .login .localAccount .entry .buttons {
    margin-top: 20px; }
  @media (max-width: 767px) {
    .msp-login .login .form-holder {
      max-width: 65%;
      margin: 0 auto;
      padding: 0 15px 28px; } }
  .msp-login input {
    font-size: 12px;
    height: inherit;
    width: 100%;
    display: block;
    font-weight: 300;
    padding: 8px 12px 6px;
    border: none;
    box-sizing: border-box;
    border-bottom: 2px solid #eee;
    border-radius: 6px 6px 0 0;
    outline: none;
    background: #f9f9f9;
    -webkit-box-shadow: none;
    box-shadow: none;
    -webkit-appearance: none;
    -webkit-border-radius: 6px 6px 0 0; }
  .msp-login input:active, .msp-login input:focus {
    border-bottom: 2px solid #6c8bad;
    -webkit-box-shadow: none;
    box-shadow: none; }
  .msp-login .entry-item label {
    margin-right: 15px;
    display: block;
    max-width: 100%;
    margin-bottom: 5px;
    font-weight: 700; }
  .msp-login .entry-item {
    margin-bottom: 15px; }
  .msp-login iframe#forgotPW {
    display: block;
    position: relative;
    width: 350px;
    height: 30vh;
    border: none;
    margin: auto; }
  .msp-login .forgotPWlink-holder {
    text-align: center; }
  .msp-login a.forgotPWlink {
    position: relative;
    border: none;
    margin-top: 15px; }
  .msp-login div#api .social, .msp-login div#api .create, .msp-login div#api .divider, .msp-login div#api a#forgotPassword {
    display: none; }
  .msp-login ::placeholder {
    color: transparent; }
  .msp-login :-webkit-placeholder {
    color: transparent; }
  .msp-login ::-webkit-placeholder {
    color: transparent; }
  .msp-login :-ms-input-placeholder {
    color: transparent; }
  .msp-login ::-ms-input-placeholder {
    color: transparent; }
  .msp-login :-moz-placeholder {
    color: transparent; }
  .msp-login ::-moz-placeholder {
    color: transparent; }
  .msp-login h2 {
    visibility: hidden; }
  .msp-login h2::after {
    content: 'My Cloud Services Portal';
    visibility: visible;
    display: block;
    position: relative; }
  .msp-login .entry > .entry-item > label {
    visibility: hidden; }
  .msp-login .entry > .entry-item > label::after {
    content: "Username";
    visibility: visible;
    display: block;
    position: relative; }
  .msp-login .halves:after {
    display: table;
    content: " ";
    clear: both; }
  @media screen and (-ms-high-contrast: active), (-ms-high-contrast: none) {
    .msp-login h2 {
      visibility: visible; }
    .msp-login h2::first-line {
      color: transparent; }
    .msp-login .entry > .entry-item > label {
      visibility: visible; }
    .msp-login .entry > .entry-item > label::first-line {
      color: transparent; } }
  .msp-login .no_display {
    display: none; }
  .msp-login .error_container h1 {
    color: #333;
    font-size: 1.2em;
    font-family: 'Segoe UI Light',Segoe,'Segoe UI',SegoeUI-Light-final,Tahoma,Helvetica,Arial,sans-serif;
    font-weight: lighter; }
  .msp-login .error_container p {
    color: #333;
    font-size: .8em;
    font-family: 'Segoe UI',Segoe,SegoeUI-Regular-final,Tahoma,Helvetica,Arial,sans-serif;
    margin: 14px 0; }
  .msp-login .error {
    color: #da5a5a;
    text-align: center;
    font-size: large; }
    .msp-login .errorMessage {
      color: #da5a5a;
      text-align: center;
      font-size: small; }
  .msp-login .logo_div {
    width: 240px;
    height: 50px;
    display: table;
    margin: 30px auto 60px;
    background: #e3e3e3;
    background: linear-gradient(90deg, #e3e3e3 0%, #b6b6b6 7%, #b6b6b6 13%, #e3e3e3 25%);
    background-size: 900%;
    background-position: 100% 0%;
    animation: animation 0.8s;
    animation-iteration-count: infinite;
    animation-timing-function: ease-in-out; }

@keyframes animation {
  0% {
    background-position: 100% 0%; }
  99.99% {
    background-position: -30% 0%; }
  100% {
    background-position: 100% 0%; } }


