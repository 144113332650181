/* Departments */
.department-block .table { 
  margin: 0;
}

/* Enterprise Directory */
.enterpriseDirectoryModal a.k-state-selected {
  background-color: rgb(78, 78, 78) !important;
  color: #fff !important;
}

#enterpriseDirectorySummary .k-header,
#enterpriseDirectorySummary .k-grid-header,
.enterpriseDirectoryModal .k-header,
.enterpriseDirectoryModal .k-grid-header {
    background-color: #f6f6f6;
}

#enterpriseDirectorySummary .k-grid tr.k-alt,
#enterpriseDirectorySummary .k-grid-table tr.k-alt,
.enterpriseDirectoryModal .k-grid tr.k-alt,
.enterpriseDirectoryModal .k-grid-table tr.k-alt {
  background-color: #e5f7fd !important; }

  #enterpriseDirectorySummary .k-grid-table tr td {
    background-color: unset;
  }  

.enterpriseDirectoryModal .k-grid tr.k-alt td {
    background-color: unset; }

@media print {
  body {
    background-color: red;
  }
  .do-not-print {
    display: none !important;
  }

  #enterpriseDirectoryDetails {
    background-color: blueviolet !important;
  }
}