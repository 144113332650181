.msp_resetPwd2 {
    /* Hiding social media login, new account creation link, and dividers */
    /* If IE */ }
  
  @font-face {
    .msp_resetPwd2 {
      font-family: 'Avenir';
      src: url(https://msplogin.mydigitalservices.com/fonts/Avenir.tcc) format("opentype");
      font-weight: 400;
      font-style: normal; } }
    .msp_resetPwd2 html, .msp_resetPwd2 body {
      font-family: arial, sans-serif;
      font-size: 12px;
      color: #555;
      background: RGBA(69, 88, 110, 0.05) url(https://msplogin.mydigitalservices.com/images/clouds.67c959c6.jpg);
      background-position: center;
      background-size: 100% 100%;
      background-repeat: no-repeat;
      min-height: 100vh;
      overflow: hidden;
      /*background: url('image.png') no-repeat center fixed;
          background-size: cover;    */ }
    @media (max-height: 600px) {
      .msp_resetPwd2 html, .msp_resetPwd2 body {
        overflow: visible; } }
    .msp_resetPwd2 h1, .msp_resetPwd2 h2, .msp_resetPwd2 h3, .msp_resetPwd2 h4, .msp_resetPwd2 h5, .msp_resetPwd2 h6 {
      font-weight: normal; }
    .msp_resetPwd2 .red {
      color: #da5a5a; }
    .msp_resetPwd2 a, .msp_resetPwd2 button {
      color: #6c8bad;
      -webkit-transition: all 1000ms ease;
      -moz-transition: all 1000ms ease;
      -ms-transition: all 1000ms ease;
      -o-transition: all 1000ms ease;
      transition: all 1000ms ease;
      cursor: pointer; }
    .msp_resetPwd2 button {
      background: #fff;
      border: 1px solid #c7d9e6;
      min-width: 80px;
      border-radius: 25px;
      text-align: center;
      margin: 8px 3px 0;
      white-space: nowrap; }
    .msp_resetPwd2 #next:hover {
      background: RGBA(109, 139, 172, 0.1); }
    .msp_resetPwd2 a.btn.orange {
      background: #ff9e15;
      border-color: #ff9e15;
      color: #fff; }
    .msp_resetPwd2 a.btn.orange:hover {
      background: RGBA(253, 157, 47, 0.7); }
    .msp_resetPwd2 a.btn.blue {
      background: #00b5e1;
      border-color: #00b5e1;
      color: #fff; }
    .msp_resetPwd2 a.btn.blue:hover {
      background: RGBA(30, 182, 223, 0.7); }
    .msp_resetPwd2 a.btn.green {
      background: #c3d500;
      border-color: #c3d500;
      color: #fff; }
    .msp_resetPwd2 a.btn.green:hover {
      background: RGBA(195, 211, 44, 0.7); }
    .msp_resetPwd2 a.btn.nuetral {
      background: #6c8bad;
      border-color: #6c8bad;
      color: #fff; }
    .msp_resetPwd2 a.btn.nuetral:hover {
      background: RGBA(109, 139, 172, 0.7); }
    .msp_resetPwd2 a.no-btn {
      background: transparent;
      color: #6c8bad;
      padding: 2px 0;
      border-bottom: 2px solid #c7d9e6;
      border-radius: 0;
      white-space: nowrap; }
    .msp_resetPwd2 a.no-btn:hover {
      padding: 2px 6px; }
    .msp_resetPwd2 a:hover, .msp_resetPwd2 a:active, .msp_resetPwd2 a:focus, .msp_resetPwd2 a:visited {
      text-decoration: none;
      outline: 0; }
    .msp_resetPwd2 .table {
      border-top: none;
      border-bottom: 1px solid #eee;
      margin-bottom: 0;
      background: #fff; }
    .msp_resetPwd2 .table.table-striped tbody tr:nth-of-type(odd) {
      background-color: rgba(0, 0, 0, 0.08); }
    .msp_resetPwd2 .table .check {
      width: 30px; }
    .msp_resetPwd2 .table .check input {
      margin: 0 auto;
      display: table; }
    .msp_resetPwd2 .table .btn {
      margin: 0; }
    .msp_resetPwd2 .table .no-btn:hover {
      padding: 2px 0; }
    .msp_resetPwd2 .table > thead > tr > th, .msp_resetPwd2 .table > tbody > tr > th {
      border-top: none;
      white-space: nowrap; }
    .msp_resetPwd2 .table > thead > tr > th, .msp_resetPwd2 .table > tbody > tr > th, .msp_resetPwd2 .table > tfoot > tr > th, .msp_resetPwd2 .table > thead > tr > td, .msp_resetPwd2 .table > tbody > tr > td, .msp_resetPwd2 .table > tfoot > tr > td {
      vertical-align: middle; }
    .msp_resetPwd2 .halves .half {
      float: left;
      width: 50%; }
    .msp_resetPwd2 .halves .half:first-child {
      padding-right: 10px; }
    .msp_resetPwd2 .halves .half:last-child {
      padding-left: 10px; }
    .msp_resetPwd2 .resetPwd {
      padding-top: 50px;
      /* */
      width: 100%;
      text-align: center;      
    }
    .msp_resetPwd2 .resetPwd img {
      width: 240px;
      max-width: 100%;
      display: table;
      margin: 30px auto 60px;
    }
    @media (max-width: 767px) {
      .msp_resetPwd2 html, .msp_resetPwd2 body {
        overflow: visible; }
      .msp_resetPwd2 .resetPwd {
        /*margin-top: 0;*/
        /* SB */
        width: 100%; } }
    .msp_resetPwd2 .resetPwd .form-holder {
      display: inline-block;
      text-align: left;
      position: relative;
      background: #fff;
      margin: 0 auto;
      border-radius: 10px;
      padding: 0 28px 28px;
      width:45%;
      -webkit-box-shadow: 0px 5px 10px -2px RGBA(0, 0, 0, 0.1);
      -moz-box-shadow: 0px 5px 10px -2px RGBA(0, 0, 0, 0.1);
      -o-box-shadow: 0px 5px 10px -2px RGBA(0, 0, 0, 0.1);
      -ms-box-shadow: 0px 5px 10px -2px RGBA(0, 0, 0, 0.1);
      box-shadow: 0px 5px 10px -2px RGBA(0, 0, 0, 0.1); }
    .msp_resetPwd2 .resetPwd .localAccount {
      padding: 0 15px 15px;
      display: block; }
    .msp_resetPwd2 .resetPwd .form-holder h2 {
      font-size: 24px;
      text-align: center;
      margin: 0; }
    .msp_resetPwd2 .resetPwd .localAccount button {
      display: block;
      font-size: 16px;
      padding: 12px;
      width: 100%; }
    .msp_resetPwd2 .resetPwd .localAccount .entry .buttons {
      margin-top: 20px; }
    @media (max-width: 767px) {
      .msp_resetPwd2 .resetPwd .form-holder {
        max-width: 65%;
        margin: 0 auto;
        padding: 0 15px 28px; } }
    .msp_resetPwd2 input {
      font-size: 12px;
      height: inherit;
      width: 100%;
      display: block;
      font-weight: 300;
      padding: 8px 12px 6px;
      border: none;
      box-sizing: border-box;
      border-bottom: 2px solid #eee;
      border-radius: 6px 6px 0 0;
      outline: none;
      background: #f9f9f9;
      -webkit-box-shadow: none;
      box-shadow: none;
      -webkit-appearance: none;
      -webkit-border-radius: 6px 6px 0 0; }
    .msp_resetPwd2 input:active, .msp_resetPwd2 input:focus {
      border-bottom: 2px solid #6c8bad;
      -webkit-box-shadow: none;
      box-shadow: none; }
    .msp_resetPwd2 .entry-item label {
      margin-right: 15px;
      display: block;
      max-width: 100%;
      margin-bottom: 5px;
      font-weight: 700; }
    .msp_resetPwd2 .entry-item {
      margin-bottom: 15px; }
    .msp_resetPwd2 iframe#forgotPW {
      display: block;
      position: relative;
      width: 350px;
      height: 30vh;
      border: none;
      margin: auto; }
    .msp_resetPwd2 .forgotPWlink-holder {
      text-align: center; }
    .msp_resetPwd2 a.forgotPWlink {
      position: relative;
      border: none;
      margin-top: 15px; }
    .msp_resetPwd2 div#api .social, .msp_resetPwd2 div#api .create, .msp_resetPwd2 div#api .divider, .msp_resetPwd2 div#api a#forgotPassword {
      display: none; }
    .msp_resetPwd2 ::placeholder {
      color: transparent; }
    .msp_resetPwd2 :-webkit-placeholder {
      color: transparent; }
    .msp_resetPwd2 ::-webkit-placeholder {
      color: transparent; }
    .msp_resetPwd2 :-ms-input-placeholder {
      color: transparent; }
    .msp_resetPwd2 ::-ms-input-placeholder {
      color: transparent; }
    .msp_resetPwd2 :-moz-placeholder {
      color: transparent; }
    .msp_resetPwd2 ::-moz-placeholder {
      color: transparent; }
    .msp_resetPwd2 h2 {
      visibility: hidden;
    padding-bottom: 30px; }
    .msp_resetPwd2 h2::after {
      content: 'MFA Email Validation';
      visibility: visible;
      display: block;
      position: relative;
    text-align: center; }
    .msp_resetPwd2 .entry > .entry-item > label {
      visibility: hidden; }
    .msp_resetPwd2 .entry > .entry-item > label::after {
      content: "Username";
      visibility: visible;
      display: block;
      position: relative; }
    .msp_resetPwd2 .halves:after {
      display: table;
      content: " ";
      clear: both; }
    @media screen and (-ms-high-contrast: active), (-ms-high-contrast: none) {
      .msp_resetPwd2 h2 {
        visibility: visible; }
      .msp_resetPwd2 h2::first-line {
        color: transparent; }
      .msp_resetPwd2 .entry > .entry-item > label {
        visibility: visible; }
      .msp_resetPwd2 .entry > .entry-item > label::first-line {
        color: transparent; } }
    .msp_resetPwd2 .no_display {
      display: none; }
    .msp_resetPwd2 .error_container h1 {
      color: #333;
      font-size: 1.2em;
      font-family: 'Segoe UI Light',Segoe,'Segoe UI',SegoeUI-Light-final,Tahoma,Helvetica,Arial,sans-serif;
      font-weight: lighter; }
    .msp_resetPwd2 .error_container p {
      color: #333;
      font-size: .8em;
      font-family: 'Segoe UI',Segoe,SegoeUI-Regular-final,Tahoma,Helvetica,Arial,sans-serif;
      margin: 14px 0; }
    .msp_resetPwd2 .error {
      color: #da5a5a;
      text-align: center;
      font-size: large; }
      .msp_resetPwd2 .errorMessage {
        color: #da5a5a;
        text-align: center;
        font-size: small; }
    .msp_resetPwd2 .logo_div {
      width: 240px;
      height: 50px;
      display: table;
      margin: 30px auto 60px;
      background: #e3e3e3;
      background: linear-gradient(90deg, #e3e3e3 0%, #b6b6b6 7%, #b6b6b6 13%, #e3e3e3 25%);
      background-size: 900%;
      background-position: 100% 0%;
      animation: animation 0.8s;
      animation-iteration-count: infinite;
      animation-timing-function: ease-in-out; }
  
  @keyframes animation {
    0% {
      background-position: 100% 0%; }
    99.99% {
      background-position: -30% 0%; }
    100% {
      background-position: 100% 0%; } }
    
   .mailTxt{

   }
   .successMsg{
     display: block;
   }