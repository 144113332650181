:root {
  --theme-background: white;
  --theme-fontfamily: arial, sans-serif;
  --theme-text: #555;
  --theme-nuethree: #00b5e1;
  --theme-gridheader: rgb(101, 101, 101);
  --theme-gridtext: rgb(101, 101, 101);
  --theme-nav: #44576f;
  --theme-nav_text: #f2f2f2;
  --theme-nav_header_text: #f2f2f2;
  --theme-nav_focus: rgba(0, 0, 0, 0.2);
  --theme-border: #eee;
  --theme-accent: #00b5e1;
  --theme-infopop: rgb(108, 139, 173);
  --theme-link: #c7d9e6;
  --theme-linktext: #6c8bad;
  --theme-profile_text: #f2f2f2;
  --theme-dropdowntext: #555555;
  --theme-activetabbackground: rgba(0, 0, 0, 0);
  --theme-button-one: #c3d500;
  --theme-button-two: #ff9e15;
  --theme-button_background: rgb(255, 255, 255);
  --theme-darknav: #2b3d54;
  --theme-lightnav: #547bad;
  --theme-lightlink: lighten(#c7d9e6, 10%);
  --theme-instructionHighlight: #b3b1b1;
  --theme-primary_button_text: #6c8bad;
  --theme-profile-dropdowntext: #00b5e1; }

html,
body {
  font-family: var(--theme-fontfamily);
  font-size: 12px;
  background-color: var(--theme-background);
  color: var(--theme-text); }

h1,
h2,
h3,
h4,
h5,
h6 {
  font-weight: normal; }

h1 {
  font-size: 28px; }

h2 {
  font-size: 24px; }

h3 {
  font-size: 21px; }

h4 {
  font-size: 18px; }

h5 {
  font-size: 16px; }

h6 {
  font-size: 14px; }

small {
  color: var(--theme-nuethree); }

small.instructions_highlight {
  color: var(--theme-instructionHighlight); }

.opaque {
  opacity: 0;
  z-index: -1; }

.semi-transparent {
  opacity: 0.4; }

.red {
  color: #ff5346;
  font-weight: bold; }

a,
button {
  color: var(--theme-linktext);
  text-decoration: none;
  cursor: pointer;
  -webkit-transition: all 1000ms ease;
  -moz-transition: all 1000ms ease;
  -ms-transition: all 1000ms ease;
  -o-transition: all 1000ms ease;
  transition: all 1000ms ease; }

a.btn,
a.k-button,
button.btn,
button.k-button {
  padding: 8px 12px 6px;
  background: var(--theme-button_background);
  border: 1px solid var(--theme-link);
  display: inline-block;
  min-width: 80px;
  border-radius: 25px;
  text-align: center;
  margin: 8px 3px 0;
  color: var(--theme-primary_button_text);
  white-space: nowrap; }

a.btn:hover,
a.btn:focus,
a.k-button:hover,
a.k-button:focus,
button.btn:hover,
button.btn:focus,
button.k-button:hover,
button.k-button:focus {
  background: var(--theme-lightlink);
  color: black; }

a.btn.orange,
a.k-button.orange,
button.btn.orange,
button.k-button.orange {
  background: var(--theme-button-two);
  border-color: var(--theme-button-two);
  color: white; }

a.btn.orange:hover,
a.btn.orange:focus,
a.k-button.orange:hover,
a.k-button.orange:focus,
button.btn.orange:hover,
button.btn.orange:focus,
button.k-button.orange:hover,
button.k-button.orange:focus {
  background: RGBA(253, 157, 47, 0.7); }

a.btn.blue,
a.k-button.blue,
button.btn.blue,
button.k-button.blue {
  background: var(--theme-accent);
  border-color: var(--theme-accent);
  color: white; }

a.btn.blue:hover,
a.btn.blue:focus,
a.k-button.blue:hover,
a.k-button.blue:focus,
button.btn.blue:hover,
button.btn.blue:focus,
button.k-button.blue:hover,
button.k-button.blue:focus {
  background: RGBA(30, 182, 223, 0.7); }

a.btn.green,
a.k-button.green,
button.btn.green,
button.k-button.green {
  background: var(--theme-button-one);
  border-color: var(--theme-button-one);
  color: white; }

a.btn.green:hover,
a.btn.green:focus,
a.k-button.green:hover,
a.k-button.green:focus,
button.btn.green:hover,
button.btn.green:focus,
button.k-button.green:hover,
button.k-button.green:focus {
  background: RGBA(195, 211, 44, 0.7); }

a.btn.neutral,
a.k-button.neutral,
button.btn.neutral,
button.k-button.neutral {
  background: var(--theme-linktext);
  border-color: var(--theme-linktext);
  color: white; }

a.btn.neutral:hover,
a.btn.neutral:focus,
a.k-button.neutral:hover,
a.k-button.neutral:focus,
button.btn.neutral:hover,
button.btn.neutral:focus,
button.k-button.neutral:hover,
button.k-button.neutral:focus {
  background: RGBA(109, 139, 172, 0.7); }

a.btn.icon,
a.k-button.icon,
button.btn.icon,
button.k-button.icon {
  float: right;
  min-width: inherit;
  padding: 8px 10px 6px; }

a.no-btn,
button.no-btn {
  background: transparent;
  color: var(--theme-linktext) !important;
  padding: 2px 0;
  border: none;
  border-bottom: 2px solid var(--theme-link);
  border-radius: 0;
  white-space: nowrap; }

a.no-btn:hover,
a.no-btn:focus,
button.no-btn:hover,
button.no-btn:focus {
  padding: 2px 6px; }

a:hover,
a:active,
a:focus,
a:visited,
button:hover,
button:active,
button:focus,
button:visited {
  text-decoration: none;
  outline: none; }

select,
option,
select.form-control {
  color: var(--theme-dropdowntext); }

.tabs {
  margin-top: 20px;
  border-bottom: 1px solid var(--theme-border); }

.tabs .tab {
  padding: 10px 10px 8px;
  border: 1px solid var(--theme-border);
  border-bottom: 0;
  border-radius: 6px 6px 0 0;
  margin-right: 0px;
  display: inline-block;
  margin-left: 1px; }

.tabs .tab:not(.active) {
  background: var(--theme-nav);
  color: var(--theme-nav_text); }

.tabs .tab.active {
  color: var(--theme-linktext);
  border-color: var(--theme-link);
  background-color: var(--theme-activetabbackground); }

.tab-body .prompts {
  padding-top: 30px; }

.livexyz:after {
  height: 16px;
  background: var(--theme-button-two);
  content: "Live Data!";
  color: white;
  padding: 2px 3px;
  border-radius: 2px 2px 0 0;
  position: absolute;
  top: 0;
  font-size: 8px; }

.empty {
  padding: 30px 15px;
  text-align: center;
  background: #f9f9f9;
  border-radius: 10px; }

svg {
  fill: var(--theme-primary_button_text); }

.k-callout-n {
  border-bottom-color: white !important; }

.k-block,
.k-widget {
  border-width: 0 !important;
  color: var(--theme-text) !important; }

.k-grid-footer-wrap,
.k-grid-header-wrap {
  border-width: 0 !important; }

.k-grid table tbody tr.k-master-row {
  color: var(--theme-gridtext); }

.k-grid a.btn,
.k-grid a.k-button {
  color: var(--theme-primary_button_text) !important; }

.k-grid a {
  color: var(--theme-linktext) !important; }

.k-grid td {
  border-width: 0 0 1px 0 !important; }

.k-grid th {
  border-width: 0 0 1px 0 !important;
  font-weight: bold !important; }

.k-grid th a.k-link {
  color: var(--theme-gridheader) !important; }

.k-grid td,
.k-grid th {
  font-size: 12px; }

@media (max-width: 1024px) {
  .k-grid td,
  .k-grid th {
    padding: 4px !important; } }

.k-grid tr.k-alt {
  background-color: white !important; }

.k-filter-row > th,
.k-grid-content-locked,
.k-grid-footer,
.k-grid-footer-locked,
.k-grid-footer-wrap,
.k-grid-header,
.k-grid-header-locked,
.k-grid-header-wrap,
.k-grid td,
.k-grouping-header,
.k-grouping-header .k-group-indicator,
.k-header {
  background-color: #fff; }

.k-grid-header {
  padding-right: 0px !important; }

.k-grid-header .k-grid-header-wrap table thead tr .k-header a.k-link .k-sort-order {
  display: none; }

.no-results-msg {
  text-align: center;
  padding: 20px 0; }

.no-results-msg.full-page-table {
  margin-top: -406px;
  padding-bottom: 362px;
  position: relative; }

.table {
  border-top: none;
  border-bottom: 1px solid var(--theme-border);
  margin-bottom: 0;
  background: white; }

.table.table-striped tbody tr:nth-of-type(odd) {
  background-color: rgba(0, 0, 0, 0.08); }

.table > thead > tr > th {
  border-bottom: 1px solid var(--theme-border); }

.table .check {
  width: 30px; }

.table .check input {
  margin: 0 auto;
  display: table; }

.table .btn,
.table .k-button {
  margin: 0; }

.table .no-btn:hover,
.table .no-btn:focus {
  padding: 2px 6px; }

.table .deleteable a {
  margin: 3px 5px; }

.table > tr > th,
.table > tr > th {
  border-top: none;
  white-space: nowrap; }

.table > thead > tr > th,
.table > thead > tr > th,
.table > tbody > tr > th,
.table > tfoot > tr > th,
.table > thead > tr > td,
.table > tbody > tr > td,
.table > tfoot > tr > td {
  border-top: 1px solid var(--theme-border); }

.table th,
.table td,
.table > thead > tr > th,
.table > tbody > tr > th,
.table > tfoot > tr > th,
.table > thead > tr > td,
.table > tbody > tr > td,
.table > tfoot > tr > td {
  vertical-align: middle; }

.line-type-tab-table table,
.line-type-tab-table th,
.line-type-tab-table td {
  border: 1px solid black;
  padding: 0px 10px; }

.k-filtercell-operator {
  display: none; }

.k-filtercell .k-button,
.k-filtercell .k-button-icon,
.k-filtercell .k-clear-button-visible {
  min-width: 0;
  background-color: #f6f6f6;
  border-radius: 0;
  border-color: rgba(0, 0, 0, 0.08);
  display: inline-block;
  text-align: center;
  margin: 8px 3px 0;
  color: #656565;
  white-space: nowrap;
  padding: 0;
  height: 20px;
  width: 20px; }

.k-filtercell .k-widget .k-dropdown-wrap .k-input,
.k-filtercell .k-widget .k-state-default .k-input,
.k-filtercell .k-widget .k-state-focused .k-input,
.k-filtercell .k-dropdown .k-dropdown-wrap .k-input,
.k-filtercell .k-dropdown .k-state-default .k-input,
.k-filtercell .k-dropdown .k-state-focused .k-input,
.k-filtercell .k-header .k-dropdown-wrap .k-input,
.k-filtercell .k-header .k-state-default .k-input,
.k-filtercell .k-header .k-state-focused .k-input {
  height: 20px;
  font-size: 12px; }

.k-filtercell .k-widget .k-dropdown-wrap .k-select,
.k-filtercell .k-widget .k-state-default .k-select,
.k-filtercell .k-widget .k-state-focused .k-select,
.k-filtercell .k-dropdown .k-dropdown-wrap .k-select,
.k-filtercell .k-dropdown .k-state-default .k-select,
.k-filtercell .k-dropdown .k-state-focused .k-select,
.k-filtercell .k-header .k-dropdown-wrap .k-select,
.k-filtercell .k-header .k-state-default .k-select,
.k-filtercell .k-header .k-state-focused .k-select {
  height: 20px;
  width: 20px; }

.k-filtercell .k-filtercell-wrapper .k-widget .k-numeric-wrap,
.k-filtercell .k-filtercell-wrapper .k-numerictextbox .k-numeric-wrap {
  height: 20px;
  min-width: 30px;
  max-width: 90px; }

.k-filtercell .k-filtercell-wrapper .k-widget .k-numeric-wrap .k-input,
.k-filtercell .k-filtercell-wrapper .k-widget .k-numeric-wrap .k-formatted-value,
.k-filtercell .k-filtercell-wrapper .k-numerictextbox .k-numeric-wrap .k-input,
.k-filtercell .k-filtercell-wrapper .k-numerictextbox .k-numeric-wrap .k-formatted-value {
  height: 20px;
  font-size: 12px; }

.k-filtercell .k-filtercell-wrapper .k-widget .k-numeric-wrap .k-select,
.k-filtercell .k-filtercell-wrapper .k-numerictextbox .k-numeric-wrap .k-select {
  display: none; }

.k-filtercell .k-filtercell-wrapper .k-input.k-textbox,
.k-filtercell .k-filtercell-wrapper .k-textarea,
.k-filtercell .k-filtercell-wrapper .k-textbox {
  height: 20px;
  min-width: 30px;
  max-width: 90px; }

.k-filtercell .k-filtercell-wrapper .k-filtercell-operator .k-widget,
.k-filtercell .k-filtercell-wrapper .k-filtercell-operator .k-dropdown,
.k-filtercell .k-filtercell-wrapper .k-filtercell-operator .k-header,
.k-filtercell .k-filtercell-wrapper .k-filtercell-operator .k-dropdown-operator {
  height: 20px;
  width: 20px;
  bottom: 4px; }

.k-filtercell .k-filtercell-wrapper .k-filtercell-operator .k-button,
.k-filtercell .k-filtercell-wrapper .k-filtercell-operator .k-button-icon,
.k-filtercell .k-filtercell-wrapper .k-filtercell-operator .k-clear-button-visible {
  min-width: 0;
  background-color: #f6f6f6;
  border-radius: 0;
  border-color: rgba(0, 0, 0, 0.08);
  display: inline-block;
  text-align: center;
  margin: 8px 3px 0;
  color: #656565;
  white-space: nowrap;
  padding: 0;
  height: 20px;
  width: 20px;
  bottom: 8px; }

.halves .half {
  float: left;
  width: 50%; }

.halves .half:first-child {
  padding-right: 10px; }

.halves .half:last-child {
  padding-left: 10px; }

.halves:after {
  display: table;
  content: " ";
  clear: both; }

.alerts .alert {
  display: none;
  width: 360px;
  padding: 0;
  border-radius: 10px;
  color: white;
  position: fixed;
  top: 15px;
  left: 255px;
  z-index: 240;
  font-size: 16px;
  -webkit-box-shadow: 0px 5px 26px -2px var(--theme-link);
  -moz-box-shadow: 0px 5px 26px -2px var(--theme-link);
  -o-box-shadow: 0px 5px 26px -2px var(--theme-link);
  -ms-box-shadow: 0px 5px 26px -2px var(--theme-link);
  box-shadow: 0px 5px 26px -2px var(--theme-link); }

.alerts .alert .header {
  background: rgba(0, 0, 0, 0.08);
  padding: 6px 15px;
  border-radius: 10px 10px 0 0;
  font-weight: bold; }

.alerts .alert .message {
  padding: 10px 15px; }

.alerts .alert.fail {
  background: #ff5346; }

.alerts .alert.success {
  background: #44c466; }

.alerts .cross {
  position: absolute;
  top: 8px;
  right: 10px;
  font-size: 36px;
  line-height: 0;
  height: 20px;
  width: 20px;
  color: rgba(255, 255, 255, 0.8);
  text-align: center;
  padding: 5px 0;
  cursor: pointer;
  -webkit-transition: all 500ms ease;
  -moz-transition: all 500ms ease;
  -ms-transition: all 500ms ease;
  -o-transition: all 500ms ease;
  transition: all 500ms ease; }

.alerts:hover .cross,
.alerts:focus .cross {
  color: white; }

.inline .spinner {
  margin: 0;
  width: 70px;
  text-align: left; }

.inline .spinner > div {
  width: 6px;
  height: 6px;
  margin-right: 3px;
  background-color: var(--theme-link); }

.inline-fail {
  color: #ff5346;
  padding: 3px 5px 1px;
  border-radius: 6px;
  margin: 5px -5px; }

.inline-success {
  color: #44c466;
  padding: 3px 5px 1px;
  border-radius: 6px;
  margin: 5px -5px; }

.sucess-msg {
  color: #44c466;
  padding: 3px 5px 1px;
  border-radius: 6px;
  margin: 5px -5px; }

.search {
  position: relative; }

.search .form-control {
  border: 1px solid var(--theme-border);
  border-radius: 64px;
  font-size: 16px;
  padding: 5px 12px 4px 35px; }

.search .form-control:hover,
.search .form-control:focus {
  border: 1px solid var(--theme-border); }

.search svg {
  position: absolute;
  left: 14px;
  top: 9px;
  fill: var(--theme-accent); }

.login,
.resetPwd {
  background: RGBA(69, 88, 110, 0.05) url(../images/clouds.jpg);
  background-position: center;
  background-size: cover;
  min-height: 100vh;
  /*display: flex;
  flex-direction: column;
  flex-wrap: nowrap;
  justify-content: center;
  align-items: center;
  align-content: center;*/ }

.login .login-holder {
  padding: 30px;
  padding-top: 200px;
  width: 100%; }

.login .login-holder img {
  width: 240px;
  max-width: 100%;
  display: table;
  margin: 30px auto;
  fill: white; }

@media (max-width: 767px) {
  .login .login-holder {
    margin-top: 0;
    padding: 15px; } }

.login .login-holder .btn {
  background: var(--theme-accent);
  border-color: var(--theme-accent);
  color: white; }

.login .login-form {
  width: 420px;
  background: white;
  margin: 0 auto;
  border-radius: 10px;
  padding: 30px;
  -webkit-box-shadow: 0px 5px 10px -2px RGBA(0, 0, 0, 0.1);
  -moz-box-shadow: 0px 5px 10px -2px RGBA(0, 0, 0, 0.1);
  -o-box-shadow: 0px 5px 10px -2px RGBA(0, 0, 0, 0.1);
  -ms-box-shadow: 0px 5px 10px -2px RGBA(0, 0, 0, 0.1);
  box-shadow: 0px 5px 10px -2px RGBA(0, 0, 0, 0.1); }

.login .login-form h1 {
  font-size: 24px;
  text-align: center;
  margin-top: 0;
  margin-bottom: 30px; }

.login .login-form .fg {
  float: right; }

.login .login-form .btn,
.login .login-form .k-button {
  display: block;
  /*background: $link;*/
  font-size: 16px;
  padding: 12px; }

.login .login-form .btn.fb,
.login .login-form .k-button.fb {
  background: #3b5998;
  color: white;
  border: none; }

.login .login-form .btn.li,
.login .login-form .k-button.li {
  background: #007bb6;
  color: white;
  border: none; }

.login .login-form .btn.go,
.login .login-form .k-button.go {
  background: #df4a32;
  color: white;
  border: none; }

.login .login-form .or {
  display: none; }

.login .login-form .actions {
  margin-top: 20px; }

@media (max-width: 767px) {
  .login .login-form {
    max-width: 100%;
    width: 520px;
    margin: 0 auto;
    padding: 30px 15px; }
  .login .login-form .left-side {
    border-right: none;
    padding: 0; }
  .login .login-form .right-side {
    padding: 15px 0 15px 0; }
  .login .login-form .or {
    display: block;
    margin-bottom: 40px; }
  .login .login-form .or hr {
    margin-top: 30px;
    margin-bottom: 30px; }
  .login .login-form .or span {
    position: absolute;
    top: 33px;
    left: 50%;
    margin-left: -14px;
    background: white;
    text-transform: uppercase;
    padding: 5px; } }

.redirect.container {
  width: 100%;
  height: 100vh;
  background: #ebedf1;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center; }

.redirect.container img {
  width: 360px; }

.redirect.spinner {
  width: 240px; }

.redirect.spinner > div {
  margin: 10px;
  width: 20px;
  height: 20px;
  background: #fff; }

.login-error.container {
  width: 100%;
  height: 100vh;
  background: #ebedf1; }

.login-error.container .summary {
  position: absolute;
  top: 40%;
  left: 50%;
  margin: 0;
  width: 360px;
  margin-left: -180px; }

.login-error.container .summary .error {
  font-size: 16px;
  color: #6c8bad;
  background: #fff;
  padding: 0 20px 20px 20px;
  border: 1px solid #ccdce8;
  border-radius: 8px; }

.login-error.container .summary .btn {
  margin: 8px 0px;
  border-radius: 6px;
  background: #6c8bad;
  color: #fff;
  font-size: 18px; }

.login-error.container .summary .btn:hover,
.login-error.container .summary .btn:focus {
  background: #476a90; }

.search-control {
  background: var(--theme-lightnav) !important;
  color: white;
  -webkit-transition: all 250ms ease;
  -moz-transition: all 250ms ease;
  -ms-transition: all 250ms ease;
  -o-transition: all 250ms ease;
  transition: all 250ms ease;
  font-size: 16px;
  padding: 3px 12px 2px 35px; }

.search-control:hover,
.search-control:focus {
  background-color: var(--theme-background) !important;
  border: none;
  color: var(--theme-text) !important; }

.search-control::placeholder,
.search-control::-webkit-input-placeholder,
.search-control::-moz-placeholder,
.search-control:-ms-input-placeholder,
.search-control:placeholder-shown {
  color: var(--theme-profile_text); }

.TC-modal .modal .modal-content {
  width: 120%; }

.ad-user-search {
  position: relative; }

.ad-user-search .form-control {
  -webkit-transition: all 250ms ease;
  -moz-transition: all 250ms ease;
  -ms-transition: all 250ms ease;
  -o-transition: all 250ms ease;
  transition: all 250ms ease;
  border: none; }

.ad-user-search .form-control:hover,
.ad-user-search .form-control:focus {
  border: none;
  color: var(--theme-text); }

.ad-user-search svg {
  top: 8px; }

.ad-user-search .search-results {
  display: none;
  max-height: 372px;
  width: 70%;
  left: 30%;
  overflow-y: scroll;
  background: white;
  position: absolute;
  font-size: 12px;
  padding-bottom: 10px;
  margin-top: 5px;
  -webkit-box-shadow: 0px 5px 26px -2px var(--theme-link);
  -moz-box-shadow: 0px 5px 26px -2px var(--theme-link);
  -o-box-shadow: 0px 5px 26px -2px var(--theme-link);
  -ms-box-shadow: 0px 5px 26px -2px var(--theme-link);
  box-shadow: 0px 5px 26px -2px var(--theme-link); }

.ad-user-search .search-results .search-header {
  border-bottom: 1px solid var(--theme-border);
  padding: 2px 8px;
  font-weight: bold;
  font-size: 12px;
  margin-top: 15px; }

.ad-user-search .search-results .search-item {
  padding: 4px 8px 4px;
  cursor: pointer; }

.ad-user-search .search-results .search-item.more {
  display: block; }

.ad-user-search .search-results .search-item.more a {
  color: var(--theme-accent); }

.ad-user-search .search-results .search-item:hover,
.ad-user-search .search-results .search-item:focus {
  background: RGBA(69, 88, 110, 0.05); }

.ad-user-search .search-results .no-results {
  padding: 2px 8px;
  font-weight: bold;
  font-size: 12px;
  margin-top: 15px; }

.global-search {
  float: right;
  position: relative;
  width: 320px;
  z-index: 110; }

.global-search .form-control {
  -webkit-transition: all 250ms ease;
  -moz-transition: all 250ms ease;
  -ms-transition: all 250ms ease;
  -o-transition: all 250ms ease;
  transition: all 250ms ease;
  border: none;
  border-radius: 64px;
  background: var(--theme-lightnav);
  color: white;
  font-size: 16px;
  padding: 3px 12px 2px 35px; }

.global-search .form-control:hover,
.global-search .form-control:focus {
  border: none;
  color: var(--theme-text); }

.global-search svg {
  top: 8px; }

.global-search .search-results {
  display: none;
  max-height: 372px;
  width: 100%;
  overflow-y: scroll;
  min-width: 240px;
  background: white;
  position: absolute;
  z-index: 110;
  font-size: 12px;
  padding-bottom: 10px;
  margin-top: 5px;
  -webkit-box-shadow: 0px 5px 26px -2px var(--theme-link);
  -moz-box-shadow: 0px 5px 26px -2px var(--theme-link);
  -o-box-shadow: 0px 5px 26px -2px var(--theme-link);
  -ms-box-shadow: 0px 5px 26px -2px var(--theme-link);
  box-shadow: 0px 5px 26px -2px var(--theme-link);
  border-radius: 10px; }

.global-search .search-results .search-header {
  border-bottom: 1px solid var(--theme-border);
  padding: 2px 8px;
  font-weight: bold;
  font-size: 12px;
  margin-top: 15px; }

.global-search .search-results .search-item {
  padding: 4px 8px 4px;
  cursor: pointer; }

.global-search .search-results .search-item.more {
  display: block; }

.global-search .search-results .search-item.more a {
  color: var(--theme-accent); }

.global-search .search-results .search-item:hover,
.global-search .search-results .search-item:focus {
  background: RGBA(69, 88, 110, 0.05); }

.global-search .search-results .no-results {
  padding: 2px 8px;
  font-weight: bold;
  font-size: 12px;
  margin-top: 15px; }

.navbar {
  padding: 8px;
  background: var(--theme-darknav);
  position: fixed;
  top: 0;
  height: 43px;
  width: 100%;
  z-index: 10;
  font-size: 18px;
  border-radius: 0; }

.navbar .mobile {
  display: none; }

.navbar .mobile a.nav-button svg {
  fill: var(--theme-profile_text); }

.navbar .search {
  margin-top: 3px; }

.navbar .profile {
  float: right;
  padding: 0px 10px 0;
  font-size: 14px;
  color: var(--theme-link); }

.navbar .profile a {
  color: var(--theme-link); }

.navbar .profile svg {
  fill: var(--theme-accent);
  position: relative;
  top: -22px; }

.navbar .dropdown {
  float: right;
  font-size: smaller;
  margin-top: 3px;
  margin-left: 10px; }

.navbar .dropdown a {
  color: var(--theme-profile_text); }

.navbar .btn-default:active,
.navbar .btn-default:hover,
.navbar .btn-default:focus,
.navbar .btn-default.active:hover,
.navbar .open > .dropdown-toggle.btn-default:hover,
.navbar .btn-default:active:focus,
.navbar .btn-default.active:focus,
.navbar .open > .dropdown-toggle.btn-default:focus,
.navbar .btn-default:active.focus,
.navbar .btn-default.active.focus,
.navbar .open > .dropdown-toggle.btn-default.focus {
  color: var(--theme-accent);
  background: transparent;
  outline: none; }

.navbar .dropdown .btn,
.navbar .dropdown .k-button,
.sidebar .dropdown .btn,
.sidebar .dropdown .k-button {
  background: transparent;
  color: var(--theme-nav_text);
  margin: -2px 0 0 10px;
  border: none; }

.navbar .dropdown .btn .caret,
.navbar .dropdown .k-button .caret,
.sidebar .dropdown .btn .caret,
.sidebar .dropdown .k-button .caret {
  margin-left: 5px; }

.navbar .dropdown a.menubtn,
.navbar .dropdown a.dropdown-toggle,
.sidebar .dropdown a.menubtn,
.sidebar .dropdown a.dropdown-toggle {
  color: var(--theme-profile_text); }

.navbar .dropdown .dropdown-menu,
.sidebar .dropdown .dropdown-menu {
  border-radius: 10px;
  border: none;
  right: 0;
  left: inherit; }

.navbar .dropdown .dropdown-menu a,
.sidebar .dropdown .dropdown-menu a {
  color: var(--theme-profile-dropdowntext); }

@media (max-width: 768px) {
  .navbar {
    padding: 10px;
    height: 70px; }
  .navbar .mobile {
    display: block; }
  .navbar .mobile img {
    width: 50px; }
  .navbar .mobile .nav-button {
    position: absolute;
    right: 10px;
    height: 50px;
    width: 50px;
    text-align: center;
    padding: 6px;
    fill: var(--theme-nav); }
  .navbar .global-search,
  .navbar .dropdown,
  .navbar .profile {
    display: none; }
  .cards {
    margin-top: 30px; }
  .sidebar .logo {
    display: none !important; }
  .sidebar .dropdown-menu {
    min-width: 112px; } }

.sidebar {
  width: 210px;
  background: var(--theme-nav);
  position: fixed;
  z-index: 10;
  top: 15px;
  left: 15px;
  bottom: 15px;
  border-radius: 10px;
  -webkit-box-shadow: 0px 5px 26px -2px RGBA(69, 88, 110, 0.5);
  -moz-box-shadow: 0px 5px 26px -2px RGBA(69, 88, 110, 0.5);
  -o-box-shadow: 0px 5px 26px -2px RGBA(69, 88, 110, 0.5);
  -ms-box-shadow: 0px 5px 26px -2px RGBA(69, 88, 110, 0.5);
  box-shadow: 0px 5px 26px -2px RGBA(69, 88, 110, 0.5);
  -webkit-transition: all 250ms ease;
  -moz-transition: all 250ms ease;
  -ms-transition: all 250ms ease;
  -o-transition: all 250ms ease;
  transition: all 250ms ease; }

@media (max-width: 768px) {
  .sidebar {
    top: 78px;
    left: inherit;
    right: -220px; } }

.sidebar.show {
  -moz-transform: translateX(-228px);
  -webkit-transform: translateX(-228px);
  -o-transform: translateX(-228px);
  -ms-transform: translateX(-228px);
  transform: translateX(-228px);
  z-index: 10; }

.sidebar .logo {
  display: block;
  padding: 28px 28px 20px; }

.sidebar .logo img {
  width: 100%; }

.sidebar .dropdown {
  float: left;
  padding: 5px;
  z-index: 120;
  display: none; }

@media (max-width: 768px) {
  .sidebar .dropdown {
    display: block; } }

.sidebar .dropdown button.btn {
  margin: unset; }

.sidebar .global-search {
  display: none;
  float: none;
  width: 100%;
  padding: 10px 10px 0; }

.sidebar .global-search .form-control:hover,
.sidebar .global-search .form-control:focus {
  border: none;
  color: var(--theme-text); }

.sidebar .global-search svg {
  top: 50px;
  left: 18px; }

@media (max-width: 768px) {
  .sidebar .global-search {
    display: block; } }

.sidebar .nav {
  max-height: 70%;
  overflow-y: auto;
  scrollbar-color: var(--theme-nav) var(--theme-linktext); }

.sidebar .nav::-moz-scrollbarthumb-vertical {
  border-radius: 10px;
  background: var(--theme-nav);
  box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.5); }

.sidebar .nav::-moz-scrollbartrack-vertical {
  box-shadow: inset 0 0 10px var(--theme-linktext);
  border-radius: 10px; }

.sidebar .nav::-webkit-scrollbar {
  width: 12px;
  height: 12px; }

.sidebar .nav::-webkit-scrollbar-thumb {
  border-radius: 10px;
  background: var(--theme-nav);
  box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.5); }

.sidebar .nav::-webkit-scrollbar-track {
  box-shadow: inset 0 0 10px var(--theme-linktext);
  border-radius: 10px; }

.sidebar .nav .nav-head {
  display: block;
  padding: 6px 15px 4px;
  color: var(--theme-nav_header_text);
  font-weight: bold;
  position: relative;
  background: var(--theme-darknav);
  font-size: 12px;
  margin-top: 8px;
  margin-bottom: 8px; }

.sidebar .nav .nav-head svg {
  fill: var(--theme-nav_text);
  position: absolute;
  top: 5px;
  right: 15px; }

.sidebar .nav .nav-item {
  display: block;
  padding: 8px 15px 6px 30px;
  color: var(--theme-nav_text);
  background: var(--theme-nav);
  font-size: 12px;
  -webkit-transition: all 500ms ease;
  -moz-transition: all 500ms ease;
  -ms-transition: all 500ms ease;
  -o-transition: all 500ms ease;
  transition: all 500ms ease; }

@media (max-width: 1240px) {
  .sidebar .nav .nav-item {
    font-size: 10px; } }

.sidebar .nav .nav-item svg {
  fill: var(--theme-nav_text);
  position: relative;
  top: 2px;
  margin-right: 10px;
  -webkit-transition: all 1000ms ease;
  -moz-transition: all 1000ms ease;
  -ms-transition: all 1000ms ease;
  -o-transition: all 1000ms ease;
  transition: all 1000ms ease; }

.sidebar .nav .nav-item .name {
  display: inline-block;
  -webkit-transition: transform 1000ms ease;
  -moz-transition: transform 1000ms ease;
  -ms-transition: transform 1000ms ease;
  -o-transition: transform 1000ms ease;
  transition: transform 1000ms ease; }

.sidebar .nav .nav-item .number {
  font-size: 8px;
  margin-left: 10px;
  display: block; }

.sidebar .nav .nav-item.macd-link {
  background: var(--theme-darknav);
  margin: 10px 15px;
  text-align: center;
  font-weight: bold;
  border-radius: 6px;
  padding: 8px 30px;
  color: var(--theme-nav_text); }

.sidebar .nav .nav-item.macd-link:hover {
  background: var(--theme-darknav); }

.sidebar .nav .nav-item.selected,
.sidebar .nav .nav-item:hover,
.sidebar .nav .nav-item:focus {
  color: var(--theme-accent);
  text-decoration: none;
  background: var(--theme-nav_focus); }

.sidebar .nav .nav-item:hover .name {
  -moz-transform: translateX(-12px);
  -webkit-transform: translateX(-12px);
  -o-transform: translateX(-12px);
  -ms-transform: translateX(-12px);
  transform: translateX(-12px); }

.sidebar .nav .nav-item:hover svg {
  fill: var(--theme-accent);
  -moz-transform: translateX(-10px);
  -webkit-transform: translateX(-10px);
  -o-transform: translateX(-10px);
  -ms-transform: translateX(-10px);
  transform: translateX(-10px); }

.sidebar .nav .nav-item.disabled {
  color: var(--theme-nav_text);
  background: unset;
  pointer-events: none;
  cursor: pointer; }

@media only screen and (max-width: 520px) {
  .sidebar .help-center {
    /* bottom: 0; */
    width: 100%;
    /* background: rgba(0, 0, 0, 0.1); */
    border-radius: 0 0 10px 10px; } }

.sidebar .help-center {
  position: absolute;
  bottom: 0;
  width: 100%;
  background: rgba(0, 0, 0, 0.1);
  border-radius: 0 0 10px 10px; }

.sidebar .help-center .help-item {
  display: block;
  padding: 5px 30px;
  color: var(--theme-nav_text);
  font-size: 10px;
  font-weight: bold;
  letter-spacing: 0.5px;
  -webkit-transition: all 500ms ease;
  -moz-transition: all 500ms ease;
  -ms-transition: all 500ms ease;
  -o-transition: all 500ms ease;
  transition: all 500ms ease; }

.sidebar .help-center .help-item a {
  display: block;
  color: white; }

.sidebar .help-center .help-item:first-child {
  padding-top: 10px; }

.sidebar .help-center .help-item:first-child:hover,
.sidebar .help-center .help-item:first-child:focus {
  background: var(--theme-button-two);
  color: var(--theme-text); }

.sidebar .help-center .help-item:last-child {
  padding-bottom: 10px; }

.sidebar .help-center .help-item:last-child:hover,
.sidebar .help-center .help-item:last-child:focus {
  background: var(--theme-accent);
  color: var(--theme-text);
  border-radius: 0 0 10px 10px; }

.sidebar .help-center .help-item:hover,
.sidebar .help-center .help-item:focus {
  text-decoration: none;
  background: rgba(0, 0, 0, 0.1); }

.sidebar.visible {
  right: unset;
  left: 10px; }

.body {
  background: RGBA(69, 88, 110, 0.05);
  min-height: 100vh;
  padding-top: 43px; }

.body .header {
  background: white;
  padding: 15px 15px 15px 240px; }

.body .container {
  padding-left: 235px;
  padding-right: 10px; }

@media (max-width: 768px) {
  .body .container {
    padding: 15px; } }

.body .container.full-card {
  padding: 0 20px 15px 245px; }

@media (max-width: 768px) {
  .body .container.full-card {
    padding: 15px; } }

.body .container.full-card .card {
  width: 100%;
  padding: 30px; }

.body .container.full-card .card .card-actions {
  top: 13px; }

@media (max-width: 992px) {
  .body .container.full-card .card {
    padding: 15px; }
  .body .container.full-card .card .card-header {
    padding: 15px; }
  .body .container.full-card .card .card-actions {
    position: static; } }

.card-header {
  border-bottom: 1px solid var(--theme-border); }

.card-header h1 {
  border-bottom: 0px !important; }

.body .container.full-card h1 {
  margin-top: 0;
  border-bottom: 1px solid var(--theme-border); }

.container.full-card header {
  border-bottom: 1px solid var(--theme-border); }

.body .container.full-card h2 {
  border-bottom: none;
  padding: 4px 8px; }

.body .container.full-card .charges-h6 {
  padding: 0px 0px 4px 8px; }

.body .container.full-card .table {
  border-top: 1px solid var(--theme-border); }

.body .container.full-card .contact-center .tag {
  background: #44c466;
  font-size: 12px;
  padding: 7px 10px 5px;
  border-radius: 10px;
  color: white;
  position: relative;
  top: -13px;
  float: right; }

@media (max-width: 992px) {
  .body .container.full-card .contact-center .tag {
    top: -8px; } }

.body .container.full-card .contact-center .contact-info {
  background: #f9f9f9;
  margin: -10px 0 10px;
  padding: 15px 10px; }

.body .container.iframe-card {
  padding: 0 25px 0 245px; }

@media (max-width: 768px) {
  .body .container.iframe-card {
    padding: 20px 15px; } }

.body .container.iframe-card h2 {
  margin-bottom: -8px;
  background: white;
  position: relative;
  z-index: 5; }

.body .container.iframe-card .iframe-holder {
  background: white; }

.body .container.iframe-card iframe {
  background: white;
  width: 100%;
  min-height: 85vh; }

.body .container.iframe-card iframe:after {
  display: table;
  content: " ";
  clear: both; }

.body .user-info .ext {
  margin-left: 15px; }

.body .user-info label {
  font-size: 10px;
  color: var(--theme-nuethree);
  font-weight: 200;
  margin: 0px 8px;
  position: relative;
  top: -1px; }

.body .user-info select.form-control {
  font-size: 14px;
  border: 3px solid #f9f9f9;
  border-bottom: 2px solid var(--theme-border);
  -webkit-box-shadow: none;
  box-shadow: none;
  color: var(--theme-dropdowntext); }

.body .user-info .items .item {
  float: left;
  width: 50%;
  padding-right: 10px; }

@media (max-width: 769px) {
  .body .user-info .items .item {
    width: 100%; } }

.body .user-info .items:after {
  display: table;
  content: " ";
  clear: both; }

.column {
  width: 33.33333%;
  padding: 0 10px;
  float: left; }

@media (max-width: 1440px) {
  .column {
    width: 50%; } }

@media (max-width: 992px) {
  .column {
    width: 100%; } }

.card-holder {
  width: 50%;
  padding: 0 10px; }

@media (max-width: 992px) {
  .card-holder {
    width: 100%; } }

@media screen and (min-width: 1360px) {
  .container {
    width: 100%; } }

.infopop {
  margin-left: 0; }

.infopop svg {
  fill: var(--theme-infopop); }

.swap .visible {
  margin-top: 16px; }

.swap .mac-validation {
  margin-left: 5px; }

.swap .mac-validation > div {
  display: inline; }

.k-tooltip {
  background-color: white !important;
  color: var(--theme-text) !important;
  border: none;
  border-radius: 10px;
  max-width: 300px !important;
  padding: 20px;
  max-height: 400px;
  overflow: hidden;
  -webkit-box-shadow: 0px 5px 26px -2px RGBA(69, 88, 110, 0.5) !important;
  -moz-box-shadow: 0px 5px 26px -2px RGBA(69, 88, 110, 0.5) !important;
  -o-box-shadow: 0px 5px 26px -2px RGBA(69, 88, 110, 0.5) !important;
  -ms-box-shadow: 0px 5px 26px -2px RGBA(69, 88, 110, 0.5) !important;
  box-shadow: 0px 5px 26px -2px RGBA(69, 88, 110, 0.5) !important; }

.k-tooltip ul {
  padding: 0;
  list-style-type: none; }

.k-tooltip ul li {
  border-bottom: 1px dotted var(--theme-nuethree);
  padding: 5px 2px 0; }

.k-tooltip ul li a {
  float: right; }

.k-callout-s {
  border-top-color: white !important; }

.k-tooltip-closable .k-tooltip-content {
  padding-right: 0 !important; }

.k-tooltip-closable .k-tooltip-content p {
  padding-right: 20px; }

.bg {
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 105;
  background: RGBA(69, 88, 110, 0.9); }

.modal-backdrop {
  position: fixed !important;
  bottom: 0;
  z-index: 10;
  border: none;
  background: RGBA(69, 88, 110, 0.9); }

.modal-backdrop.in {
  opacity: 1; }

.modal {
  display: none;
  border-radius: 10px;
  overflow: hidden;
  padding: 60px 50px 30px;
  max-height: inherit; }

@media (max-width: 768px) {
  .modal {
    padding: 30px 0; } }

.modal .modal-header {
  position: relative;
  border-bottom: none; }

.modal .modal-header h4 {
  margin-top: 0;
  text-align: center;
  font-size: 24px; }

@media (max-width: 540px) {
  .modal .modal-header h4 {
    font-size: 18px; } }

@media (max-width: 768px) {
  .modal .modal-body {
    overflow: scroll; } }

.modal .modal-content {
  padding: 30px 50px;
  border-radius: 10px;
  -webkit-box-shadow: none;
  box-shadow: none;
  border: none; }

@media (max-width: 768px) {
  .modal .modal-content {
    padding: 15px 0; } }

.modal .modal-content p {
  margin-bottom: 20px; }

.modal .modal-content .close {
  position: absolute;
  top: -20px;
  right: -50px;
  border: none;
  font-size: 28px;
  opacity: 0.5;
  font-weight: bold;
  width: 54px;
  border-radius: 10px;
  -webkit-transition: all 500ms ease;
  -moz-transition: all 500ms ease;
  -ms-transition: all 500ms ease;
  -o-transition: all 500ms ease;
  transition: all 500ms ease; }

@media (max-width: 768px) {
  .modal .modal-content .close {
    top: -10px;
    right: -10px; } }

.modal .modal-content .close:hover,
.modal .modal-content .close:focus,
.modal .modal-content .close:active {
  opacity: 1;
  outline: none; }

.modal .modal-content td.hidden-group {
  display: table-row; }

.modal .modal-content .hidden-group {
  /*display: none;*/
  margin: 10px -30px 0;
  background: #f6f6f8;
  padding: 22px 30px 30px;
  border-radius: 10px; }

@media (max-width: 768px) {
  .modal .modal-content .hidden-group {
    margin: 10px 0px;
    padding: 15px; } }

.modal .modal-content .hidden-group .form-control {
  background: white;
  height: 34px; }

.modal .modal-content .collapse:not(.hidden-group) {
  padding: 22px 0 0; }

.modal .modal-content .table-edit {
  position: relative;
  padding: 30px;
  background: #f6f6f8;
  margin: 0 -38px;
  border-radius: 10px; }

@media (max-width: 768px) {
  .modal .modal-content .table-edit {
    padding: 15px;
    margin: 0 -15px; } }

.modal .modal-content .table-edit .table {
  background-color: #f6f6f8; }

.modal .modal-content .table-edit .form-control,
.modal .modal-content .table-edit .multiselect-group .k-multiselect.k-state-focused,
.modal .modal-content .table-edit .multiselect-group .k-multiselect-wrap {
  background: white;
  background-color: white !important; }

.modal .modal-content .table-edit .delete {
  position: absolute;
  top: 15px;
  right: 30px;
  font-weight: bold; }

@media (max-width: 760px) {
  .modal .modal-content .table-edit .delete {
    display: none; } }

.modal .modal-content .btns {
  display: table;
  margin: 0 auto -20px; }

.modal .modal-content .btns .btn,
.modal .modal-content .btns .k-button {
  font-size: 14px;
  margin: 5px; }

.modal .modal-content .modal-footer {
  border-top: none;
  padding: 0 15px 15px; }

.modal .modal-content .modal-footer .btns {
  display: table;
  margin: 0 auto; }

.modal .modal-content .modal-footer .btns .btn,
.modal .modal-content .modal-footer .btns .k-button {
  font-size: 14px;
  margin: 5px; }

.modal .modal-content .modal-footer .close-btn {
  background: none;
  border: none;
  font-size: 42px;
  line-height: 0;
  margin: 15px auto;
  display: table; }

.modal .modal-content .modal-footer .close-btn hr {
  margin-top: 0; }

.modal .modal-content h5 {
  border-bottom: 1px solid var(--theme-border);
  padding-bottom: 5px; }

.modal .modal-content .table {
  margin-top: 30px;
  margin-bottom: 30px; }

.modal .modal-content .table.submenus {
  margin-top: 0; }

.modal .modal-content .table tr td .export {
  margin-bottom: 5px;
  display: inline-block; }

.modal .modal-content .table tr td .btn,
.modal .modal-content .table tr td .k-button {
  font-size: 12px; }

.modal .modal-content .table tr td .btns {
  margin: 2px auto 0; }

.modal .modal-content .block-options label {
  display: block;
  margin-bottom: 10px; }

.modal .modal-content .block-options label input {
  margin-right: 5px; }

.modal .modal-content .block-options .phone-nums {
  padding-top: 5px;
  margin-bottom: 0;
  margin-left: 4px;
  border-left: 3px solid white;
  padding-left: 12px; }

.modal .modal-content .block-options .phone-nums > div:after {
  display: table;
  content: " ";
  clear: both; }

.modal .modal-content .block-options .phone-nums:after {
  display: table;
  content: " ";
  clear: both; }

.modal .modal-content .block-options .phone-num {
  width: 33.3333%;
  float: left;
  padding-right: 10px;
  padding-bottom: 10px; }

@media (max-width: 540px) {
  .modal .modal-content .block-options .phone-num {
    width: 100%; } }

.modal .modal-content .block-options .phone-num label {
  margin-bottom: 0; }

.modal .modal-content .block-options .new-phone {
  margin-left: 4px;
  padding-bottom: 5px; }

.modal .modal-content .block-options.simple .phone-nums {
  padding: 0;
  margin-left: 0;
  border-left: 0; }

.modal .modal-content .block-options.simple .phone-nums .phone-num {
  width: 50%; }

.modal .modal-content .audio-options {
  padding-top: 15px; }

.modal .modal-content .audio-options label {
  display: block;
  margin-bottom: 10px; }

.modal .modal-content .audio-options label input {
  margin-right: 5px; }

.modal .modal-content .audio-options .audio-num {
  width: 50%;
  float: left;
  padding-right: 10px;
  padding-bottom: 10px; }

.modal .modal-content .audio-options .audio-num label {
  margin-bottom: 0; }

.modal .modal-content .audio-options .new-audio {
  margin-left: 4px;
  padding-bottom: 5px; }

.contact-center .btn {
  margin-left: 10px; }

.contact-center .circle {
  position: relative;
  top: 0px;
  height: 12px;
  width: 12px;
  border-radius: 100%;
  -webkit-box-shadow: 0px 5px 26px -2px var(--theme-link);
  -moz-box-shadow: 0px 5px 26px -2px var(--theme-link);
  -o-box-shadow: 0px 5px 26px -2px var(--theme-link);
  -ms-box-shadow: 0px 5px 26px -2px var(--theme-link);
  box-shadow: 0px 5px 26px -2px var(--theme-link);
  margin-left: 10px; }

.contact-center .circle.green {
  background-color: #44c466; }

.contact-center .circle.red {
  background-color: #ff5346; }

.distributionList .table > tbody + tbody {
  border-top: 1px solid var(--theme-border); }

.distributionList h5 {
  margin-bottom: 0; }

.distributionList .reset {
  margin-bottom: 20px; }

.distributionList .reset a {
  margin: 0 5px; }

.clientApp .indent {
  margin-left: 40px; }

#announcementRepositoryModal .table {
  margin-bottom: 0;
  border-bottom: none; }

#announcementRepositoryModal .play > div {
  height: 20px;
  float: left; }

#announcementRepositoryModal .play a {
  padding: 5px; }

#announcementRepositoryModal .play svg {
  fill: var(--theme-accent); }

#voicemailSettingsModal .hidden-group textarea {
  height: 120px !important;
  margin-bottom: 15px; }

.inline-success {
  display: none; }

.vm-setting-success-appear {
  opacity: 1;
  display: block; }

.vm-setting-success-appear.vm-setting-success-appear-active {
  opacity: 0.01;
  transition: opacity 1s ease-in 3s;
  display: block; }

.vm-setting-success-enter {
  opacity: 1;
  display: block; }

.vm-setting-success-enter.vm-setting-success-enter-active {
  opacity: 0.01;
  transition: opacity 1s ease-in 3s;
  display: block; }

/*.find-me {
  .hidden-group {
    .table {
      background: transparent;
      th:last-child {
        width: 195px;
      }
      .form-group {
        margin-right: 5px;
      }
    }
  }
  small {
    position: absolute;
      top: 18px;
    left: 48px;
  }
  .btns {
        margin: 0 auto !important;
  }
}*/
.sm-hide {
  display: none; }

.billing_card .card {
  min-height: 150px; }

.billing_card .card-header h2 {
  margin-bottom: 10px; }

.billing_card .actions {
  width: 170px; }

@media (max-width: 992px) {
  .billing_card .billing-item label {
    margin: 0; } }

.training.card .card-header h2 {
  border-bottom: 1px solid var(--theme-border);
  margin-bottom: 10px; }

.training.card h5 {
  border-bottom: 1px solid var(--theme-border);
  margin: 30px 0 4px;
  padding: 4px 8px; }

.training.card .training-items .training-item {
  padding: 2px 8px;
  margin-bottom: 5px;
  float: left;
  width: 33.33333%; }

.training.card .training-items:after {
  display: table;
  content: " ";
  clear: both; }

.support.card .card-header h2 {
  border-bottom: 1px solid var(--theme-border);
  margin-bottom: 10px; }

.support.card .support-items > div {
  margin-bottom: 8px;
  max-width: 840px; }

.support.card .support-items p {
  margin-bottom: 0; }

.support.card .support-items small {
  max-width: 540px;
  display: block; }

.support.card h5 {
  border-bottom: 1px solid var(--theme-border);
  margin: 30px 0 8px;
  padding: 4px 8px; }

.cards {
  /*padding-top: 45px;*/
  margin-bottom: 60px; }

.cards:after {
  display: table;
  content: " ";
  clear: both; }

.ui-state-highlight {
  background: #ff5346; }

@keyframes fadeOn {
  0% {
    opacity: 0; }
  100% {
    opacity: 1; } }

.card {
  width: 100%;
  position: relative;
  background: white;
  padding: 20px;
  margin-top: 20px;
  border-radius: 10px;
  min-height: 150px;
  animation-name: fadeOn;
  animation: fadeOn 1s forwards;
  -webkit-animation: fadeOn 1s forwards; }

@media (max-width: 768px) {
  .card {
    overflow: scroll; } }

.card.show {
  position: fixed !important;
  left: 5% !important;
  top: 30px !important;
  z-index: 110 !important;
  width: 90% !important; }

.card.show .sm-hide {
  display: table-cell; }

.card.show .card-actions .move {
  display: none; }

.card .move {
  /*cursor: move;*/
  margin: -20px -20px 0;
  padding: 20px 20px 5px; }

.card .view-more a {
  display: table;
  margin: 0 auto; }

.card .card-actions {
  position: absolute;
  top: 8px;
  right: 30px; }

.card .card-actions .move,
.card .card-actions .expand {
  display: none;
  margin-top: 12px;
  padding: 5px 5px 1px;
  float: left; }

.card .card-actions .move svg,
.card .card-actions .expand svg {
  fill: var(--theme-nuethree);
  -webkit-transition: all 500ms ease;
  -moz-transition: all 500ms ease;
  -ms-transition: all 500ms ease;
  -o-transition: all 500ms ease;
  transition: all 500ms ease; }

.card .card-actions .move:hover svg,
.card .card-actions .expand:hover svg {
  fill: var(--theme-accent); }

.card .card-actions:after {
  display: table;
  content: " ";
  clear: both; }

.card h2 {
  font-size: 24px;
  margin: 0;
  padding-bottom: 5px; }

.card .card-header.row {
  border-bottom: 1px solid var(--theme-border); }

.card .card-header.row h2 {
  border-bottom: none; }

.card .actions .btn,
.card .actions .k-button {
  margin: 2px; }

.form-group {
  position: relative; }

.form-group label {
  font-weight: normal;
  margin-right: 15px; }

.form-group .switch + small {
  display: block;
  margin-left: 45px; }

.form-group:after {
  content: "";
  display: block;
  clear: both; }

label {
  font-weight: normal; }

.form-control,
.k-input,
.k-multiselect-wrap {
  font-size: 12px;
  height: inherit;
  width: 100%;
  font-weight: 300;
  padding: 8px 12px 6px;
  border: none;
  border-bottom: 2px solid var(--theme-border);
  outline: none;
  background: #f9f9f9;
  -webkit-box-shadow: none;
  box-shadow: none;
  -webkit-appearance: none;
  border-radius: 6px 6px 0 0;
  -webkit-border-radius: 6px 6px 0 0; }

.form-control:active,
.form-control:focus,
.form-control:hover,
.k-input:active,
.k-input:focus,
.k-input:hover,
.k-multiselect-wrap:active,
.k-multiselect-wrap:focus,
.k-multiselect-wrap:hover {
  border-bottom: 2px solid var(--theme-linktext);
  -webkit-box-shadow: none;
  box-shadow: none;
  background-color: #f9f9f9; }

.multiselect-group {
  margin-top: 25px; }

.multiselect-group .k-multiselect-wrap .k-button {
  margin: 2px 3px 0 0; }

.multiselect-group .k-multiselect-wrap {
  padding: 2px 5px;
  border: none !important;
  border-bottom: 2px solid var(--theme-border) !important; }

.multiselect-group .k-input {
  padding: 8px 0 6px; }

.multiselect-group .k-dateinput-wrap .k-input,
.multiselect-group .k-dropdown-wrap .k-input,
.multiselect-group .k-multiselect-wrap .k-input,
.multiselect-group .k-numeric-wrap .k-input,
.multiselect-group .k-picker-wrap .k-input {
  margin-left: 5px; }

.multiselect-group .k-multiselect.k-state-focused,
.multiselect-group .k-multiselect-wrap {
  background-color: #f9f9f9 !important;
  -webkit-box-shadow: none !important;
  box-shadow: none !important; }

.k-list-container .k-button.k-state-active,
.k-list-container .k-button:active,
.k-list-optionlabel.k-state-selected,
.k-list .k-item.k-state-selected,
.k-menu-group .k-item.k-state-active,
.k-menu-group .k-item.k-state-selected,
.k-menu.k-context-menu .k-item.k-state-active,
.k-menu.k-context-menu .k-item.k-state-selected,
.k-spreadsheet-popup .k-button.k-state-active,
.k-spreadsheet-popup .k-button.k-state-selected,
.k-spreadsheet-popup .k-button:active {
  background-color: var(--theme-accent) !important; }

.k-datetimepicker,
.k-timepicker,
.k-datepicker {
  width: 100% !important;
  border: none;
  padding: 0; }

.k-datetimepicker .k-picker-wrap,
.k-timepicker .k-picker-wrap,
.k-datepicker .k-picker-wrap {
  padding: 0;
  border: 0;
  border-bottom: 2px solid var(--theme-border);
  height: 34px;
  border-radius: 6px 6px 0 0;
  font-size: 12px; }

.k-datetimepicker .k-picker-wrap .k-input,
.k-timepicker .k-picker-wrap .k-input,
.k-datepicker .k-picker-wrap .k-input {
  width: 100%;
  padding: 6px 12px 6px;
  height: 34px; }

.k-datetimepicker .k-select,
.k-timepicker .k-select,
.k-datepicker .k-select {
  background-color: #f9f9f9 !important;
  background-image: none !important; }

.k-datetimepicker .k-select .k-link,
.k-timepicker .k-select .k-link,
.k-datepicker .k-select .k-link {
  width: inherit !important;
  border-width: 0;
  padding: 8px; }

.k-state-focused {
  -webkit-box-shadow: none !important;
  -moz-box-shadow: none !important;
  -o-box-shadow: none !important;
  -ms-box-shadow: none !important;
  box-shadow: none !important; }

.k-tabstrip > .k-content {
  border-color: #f6f6f8;
  color: var(--theme-text);
  background-color: #f6f6f8; }

.k-calendar .k-state-selected,
.k-calendar .k-weekend.k-state-selected,
.k-drag-clue,
.k-event,
.k-panelbar .k-group > .k-item > .k-link.k-state-selected,
.k-panelbar > .k-item > .k-link.k-state-selected:not(.k-state-active),
.k-panelbar > .k-item > .k-link.k-state-selected:not(.k-state-expanded),
.k-treeview .k-in.k-state-selected {
  color: white !important;
  background-color: var(--theme-accent) !important;
  border-color: var(--theme-accent) !important; }

input[type="checkbox"],
input[type="radio"] {
  margin-right: 5px; }

select {
  cursor: pointer; }

.form-group-split .form-group {
  float: left;
  width: 50%; }

.form-group-split .form-group:first-child {
  padding-right: 15px; }

.form-group-split:after {
  content: "";
  display: block;
  clear: both; }

.top-card {
  position: relative;
  z-index: 3;
  margin: 0 10px; }

@media screen and (max-width: 1240px) {
  .top-card {
    /*margin: 0;*/ } }

.top-card .card-header-section {
  width: 50%;
  float: left; }

@media (max-width: 992px) {
  .top-card .card-header-section {
    float: none;
    width: 100%; } }

.top-card .card-header-section address {
  margin-bottom: 10px; }

.top-card .card-header-section .billing_maintenance span {
  font-size: medium;
  color: red; }

.top-card .actions {
  float: right;
  background: white; }

.top-card .actions .k-button {
  display: table;
  margin: 3px auto 7px;
  padding: 8px 30px 6px;
  background: var(--theme-button-one);
  border-color: var(--theme-button-one);
  color: white; }

.top-card .actions .no-btn {
  display: table;
  margin: 0 auto; }

@media (max-width: 992px) {
  .top-card .actions {
    position: absolute;
    top: 60px;
    right: 15px; } }

@media (max-width: 540px) {
  .top-card .actions {
    float: none;
    position: static;
    margin: 30px 0;
    width: 100%; } }

.adminInfo h2 {
  margin-bottom: 10px; }

.star svg {
  fill: var(--theme-button-two);
  position: relative;
  top: 3px; }

.dim-star svg {
  fill: var(--theme-link); }

.favorite-services {
  -ms-overflow-style: none;
  overflow: auto; }

.favorite-services .view-more {
  margin: 15px 0 0; }

.favorite-services .search {
  position: relative;
  width: 100%;
  margin-top: 10px;
  margin-bottom: 10px; }

.favorite-services .table .btn {
  font-size: 12px;
  padding: 8px 16px 6px;
  min-width: inherit; }

.quick-view .card-header {
  margin-bottom: 10px; }

.quick-view .quick-items .quick-item,
.quick-view .quick-items .item-row {
  border-bottom: 1px solid var(--theme-border);
  margin-bottom: 15px;
  padding: 0 4px; }

.quick-view .quick-items .quick-item h3,
.quick-view .quick-items .item-row h3 {
  margin: 0;
  font-size: 42px;
  font-weight: bold;
  margin-bottom: 5px;
  color: var(--theme-nuethree); }

.quick-view .quick-items .quick-item .btn,
.quick-view .quick-items .quick-item .k-button,
.quick-view .quick-items .item-row .btn,
.quick-view .quick-items .item-row .k-button {
  float: right;
  margin: 2px 0 0; }

.quick-view.call-flow .quick-items .quick-item h3 {
  color: var(--theme-linktext); }

.quick-view.devices .activity-actions {
  margin-bottom: 15px; }

.quick-view.devices .quick-items .quick-item h3,
.quick-view.devices .quick-items .item-row h3 {
  color: var(--theme-nuethree); }

.activity {
  padding: 20px 20px 0;
  min-height: 574px; }

.activity .card-header {
  margin-bottom: 10px; }

.activity .activity-actions {
  padding: 4px 0;
  border-bottom: 1px solid var(--theme-border); }

.activity .activity-actions .filter {
  padding-right: 4px;
  float: left;
  width: 50%;
  margin-bottom: 5px; }

.activity .activity-actions .filter:nth-child(2n) {
  padding-right: 0; }

.activity .activity-actions:after {
  content: "";
  display: block;
  clear: both; }

.activity .custom-dates {
  display: none; }

.activity .custom-dates .date {
  float: left;
  padding: 4px; }

.activity .custom-dates .date .no-btn {
  padding-top: 4px;
  display: inline-block; }

.activity .highlight-numbers {
  padding: 10px 10px 5px;
  border-bottom: 1px solid var(--theme-border); }

.activity .highlight-numbers .highlight-number {
  float: left;
  width: 50%;
  margin-bottom: 3px;
  font-weight: bold;
  font-size: 12px; }

.activity .highlight-numbers .highlight-number .num {
  height: 15px;
  width: 15px;
  float: left;
  margin-right: 5px; }

.activity .highlight-numbers .highlight-number .num small {
  display: block;
  font-size: 12px;
  margin-top: -5px;
  color: var(--theme-text); }

.activity .highlight-numbers .highlight-number .num.blue {
  background: var(--theme-accent); }

.activity .highlight-numbers .highlight-number .num.green {
  background: var(--theme-button-one); }

.activity .highlight-numbers .highlight-number .num.orange {
  background: var(--theme-button-two); }

.activity .highlight-numbers .highlight-number .num.neutral {
  background: var(--theme-linktext); }

.activity .highlight-numbers .highlight-number .num.red {
  background: #ff5346; }

.activity .highlight-numbers:after {
  content: "";
  display: block;
  clear: both; }

.activity .k-chart-holder {
  padding: 15px 0; }

.activity .k-chart-holder .no-data-returned {
  margin: 40px; }

.activity #chart,
.activity #otherchart {
  margin-top: 15px;
  margin-bottom: 0;
  min-height: 210px;
  width: 108%;
  margin-left: -5%; }

.activity .c3-shape.c3-shape.c3-line.c3-line-data1 {
  stroke: var(--theme-accent) !important;
  stroke-width: 1px; }

.activity .c3-chart-lines .c3-circles .c3-circle {
  display: none; }

.activity .c3 line,
.activity .c3 path {
  fill: none;
  stroke: white; }

.activity .c3 text {
  fill: rgba(56, 58, 63, 0.5); }

.activity .date-range-select label {
  padding: 10px 0 0;
  margin-bottom: 2px; }

.activity .k-datetimepicker .k-picker-wrap .k-input,
.activity .k-timepicker .k-picker-wrap .k-input,
.activity .k-datepicker .k-picker-wrap .k-input {
  background: #f9f9f9;
  border-bottom: 2px solid var(--theme-border); }

.history .history-items {
  margin-bottom: 15px; }

.history .history-items .history-item {
  margin-top: 8px;
  display: block;
  padding-right: 10px; }

.history .history-items .history-item small {
  color: var(--theme-nuethree);
  font-size: 10px; }

.vm-usage-full-report .vm-row {
  border-bottom: 1px solid var(--theme-border);
  height: 35px; }

.vm-usage-full-report .vm-row .vm-item {
  float: right; }

.vm-usage-full-report .vm-row .vm-item .no-btn {
  margin: 0px 10px;
  position: relative;
  z-index: 2; }

.ticketing-summary-page .ticketing .ticketing-row {
  border-bottom: 1px solid var(--theme-border);
  height: 35px; }

.ticketing-summary-page .ticketing .ticketing-row .ticketing-item {
  float: right; }

.ticketing-summary-page .ticketing .ticketing-row .ticketing-item .no-btn {
  margin: 0px 10px;
  position: relative;
  z-index: 2; }

.detailed-ticketing-page .dropdown-menu {
  border-radius: 10px;
  border: none;
  right: 0;
  left: inherit; }

.detailed-ticketing-page .dropdown-menu a {
  color: var(--theme-dropdowntext); }

.detailed-ticketing-page .card-content .flex-parent-wrapper {
  display: flex; }

@media (max-width: 1200px) {
  .detailed-ticketing-page .card-content .flex-parent-wrapper {
    display: block; } }

.detailed-ticketing-page .card-content .flex-parent-wrapper .flex-container-one {
  margin-right: 50px; }

@media (max-width: 1560px) {
  .detailed-ticketing-page .card-content .flex-parent-wrapper .flex-container-one {
    margin-right: 20px; } }

@media (max-width: 1360px) {
  .detailed-ticketing-page .card-content .flex-parent-wrapper .flex-container-one {
    margin-right: 10px; } }

.detailed-ticketing-page .card-content .flex-parent-wrapper .flex-container-two {
  padding-top: 10px;
  margin-right: 250px; }

@media (max-width: 1560px) {
  .detailed-ticketing-page .card-content .flex-parent-wrapper .flex-container-two {
    margin-right: 150px; } }

@media (max-width: 1360px) {
  .detailed-ticketing-page .card-content .flex-parent-wrapper .flex-container-two {
    margin-right: 10px; } }

@media (max-width: 1200px) {
  .detailed-ticketing-page .card-content .flex-parent-wrapper .flex-container-two {
    padding-top: 20px; } }

.detailed-ticketing-page .card-content .flex-parent-wrapper .flex-container-three {
  width: 50%; }

@media (max-width: 1200px) {
  .detailed-ticketing-page .card-content .flex-parent-wrapper .flex-container-three {
    padding-top: 40px; } }

.detailed-ticketing-page .card-content .flex-parent-wrapper .form-group-wrapper {
  padding-bottom: 10px; }

.detailed-ticketing-page .card-content .flex-parent-wrapper .form-group-wrapper label {
  width: 100px; }

.detailed-ticketing-page .card-content .flex-parent-wrapper .form-group-wrapper .custom-input-class {
  font-size: 12px;
  height: 35px;
  width: 200px;
  display: "inline-block";
  font-weight: 300;
  padding: 10px;
  border: none;
  border-bottom: 2px solid var(--theme-border);
  outline: none;
  background: #f9f9f9;
  -webkit-box-shadow: none;
  box-shadow: none;
  -webkit-appearance: none;
  border-radius: 6px 6px 0 0;
  -webkit-border-radius: 6px 6px 0 0; }

.detailed-ticketing-page .card-content .flex-parent-wrapper .form-group-wrapper .custom-input-class:active,
.detailed-ticketing-page .card-content .flex-parent-wrapper .form-group-wrapper .custom-input-class:focus,
.detailed-ticketing-page .card-content .flex-parent-wrapper .form-group-wrapper .custom-input-class:hover {
  border-bottom: 2px solid var(--theme-linktext);
  -webkit-box-shadow: none;
  box-shadow: none;
  background-color: #f9f9f9; }

.new-ticket-page .ticketing .dropdown-menu {
  border-radius: 10px;
  border: none;
  right: 0;
  left: inherit; }

.new-ticket-page .ticketing .dropdown-menu a {
  color: var(--theme-dropdowntext); }

.new-ticket-page .ticketing .card-content .main-container .flex-parent-wrapper {
  display: flex; }

.new-ticket-page .ticketing .card-content .main-container .flex-parent-wrapper .flex-container-one {
  width: 50%; }

.new-ticket-page .ticketing .card-content .main-container .flex-parent-wrapper .flex-container-two {
  width: 100%; }

.new-ticket-page .ticketing .card-content .main-container .flex-parent-wrapper .flex-container-two .new-ticket-anchors {
  padding-top: 10px;
  width: 70%;
  text-align: center; }

.new-ticket-page .ticketing .card-content .main-container .flex-parent-wrapper .flex-container-two .new-ticket-btns {
  padding-top: 10px;
  width: 70%;
  text-align: center; }

.manage-trunk-group-container .card-content .form-group-wrapper {
  padding-bottom: 10px; }

.manage-trunk-group-container .card-content .form-group-wrapper .custom-input-class {
  font-size: 12px;
  height: 35px;
  width: 200px;
  display: "inline-block";
  font-weight: 300;
  padding: 10px;
  border: none;
  border-bottom: 2px solid var(--theme-border);
  outline: none;
  background: #f9f9f9;
  -webkit-box-shadow: none;
  box-shadow: none;
  -webkit-appearance: none;
  border-radius: 6px 6px 0 0;
  -webkit-border-radius: 6px 6px 0 0; }

.manage-trunk-group-container .card-content .form-group-wrapper .custom-input-class:active,
.manage-trunk-group-container .card-content .form-group-wrapper .custom-input-class:focus,
.manage-trunk-group-container .card-content .form-group-wrapper .custom-input-class:hover {
  border-bottom: 2px solid var(--theme-linktext);
  -webkit-box-shadow: none;
  box-shadow: none;
  background-color: #f9f9f9; }

.voicemail .circle {
  position: relative;
  top: 0px;
  height: 6px;
  width: 6px;
  background: #ff5346;
  border-radius: 100%;
  -webkit-box-shadow: 0px 5px 26px -2px var(--theme-link);
  -moz-box-shadow: 0px 5px 26px -2px var(--theme-link);
  -o-box-shadow: 0px 5px 26px -2px var(--theme-link);
  -ms-box-shadow: 0px 5px 26px -2px var(--theme-link);
  box-shadow: 0px 5px 26px -2px var(--theme-link); }

.voicemail .vm-actions .vm-action {
  border-bottom: 1px solid var(--theme-border);
  padding: 9px 8px 8px; }

.voicemail .vm-actions .vm-action .switch {
  float: right;
  margin-top: -2px; }

.voicemail .vm-actions .vm-action .loading {
  height: 30px;
  width: 100px;
  display: inline-block;
  position: relative;
  float: right;
  margin-right: 30px;
  bottom: 20px; }

.voicemail .vm-actions .vm-action .loading .load {
  left: 5% !important; }

.voicemail .vm-actions .vm-action .inline-success {
  display: none; }

.voicemail .vm-actions .vm-action .vm-action-appear {
  opacity: 1;
  display: block; }

.voicemail .vm-actions .vm-action .vm-action-appear.vm-action-appear-active {
  opacity: 0.01;
  transition: opacity 1s ease-in 3s;
  display: block; }

.voicemail .vm-actions .vm-action .vm-action-success-enter {
  opacity: 1;
  display: block; }

.voicemail .vm-actions .vm-action .vm-action-enter.vm-action-success-enter-active {
  opacity: 0.01;
  transition: opacity 1s ease-in 3s;
  display: block; }

.voicemail .selected-action {
  display: none;
  border-bottom: 1px solid var(--theme-border);
  padding: 9px 4px 8px 4px; }

.voicemail .selected-action a {
  margin: 0 4px; }

.voicemail .view-more {
  margin: 15px 0; }

.voicemail .icon a {
  border: none; }

.voicemail .table .play > div,
.voicemail .table .actions > div {
  height: 20px;
  float: left; }

.voicemail .table .play a,
.voicemail .table .actions a {
  padding: 5px;
  position: relative;
  top: 4px; }

.voicemail .table .play svg,
.voicemail .table .actions svg {
  fill: var(--theme-accent); }

@media (max-width: 768px) {
  .voicemail .table .actions {
    min-width: 78px;
    vertical-align: top; }
  .voicemail .table .actions a {
    border: 1px solid var(--theme-accent);
    border-radius: 6px;
    width: 26px;
    height: 26px;
    display: block;
    margin: 0 2px; } }

.ip-call-logs .call-log-actions {
  padding: 4px 0;
  border-bottom: 1px solid var(--theme-border); }

.ip-call-logs .call-log-actions .custom-dates .date {
  float: left;
  padding: 4px; }

.ip-call-logs .call-log-actions .custom-dates .date .k-datepicker .k-picker-wrap {
  width: 100px; }

.ip-call-logs .call-log-actions .custom-dates .date .no-btn {
  padding-top: 4px;
  display: inline-block; }

.ip-call-logs .call-log-actions .filter:last-child {
  padding-right: 0; }

.ip-call-logs .call-log-actions:after {
  content: "";
  display: block;
  clear: both; }

.call-logs .view-more {
  margin-top: 15px; }

.call-logs .export {
  margin-top: 20px; }

.call-logs .selected-action {
  display: none;
  border-bottom: 1px solid var(--theme-border);
  padding: 9px 4px 8px 4px; }

.call-logs .selected-action a {
  margin: 0 4px; }

.call-logs .call-log-actions {
  padding: 4px 0;
  border-bottom: 1px solid var(--theme-border); }

.call-logs .call-log-actions .filter {
  padding-right: 4px;
  float: left;
  width: 33.3333%; }

.call-logs .call-log-actions .filter:last-child {
  padding-right: 0; }

.call-logs .call-log-actions:after {
  content: "";
  display: block;
  clear: both; }

.call-logs .custom-dates .date {
  float: left;
  padding: 4px; }

.call-logs .custom-dates .date .k-datepicker .k-picker-wrap {
  width: 100px; }

.call-logs .custom-dates .date .no-btn {
  padding-top: 4px;
  display: inline-block; }

.feature {
  position: relative; }

.basic-features .feature {
  border-bottom: 1px solid var(--theme-border);
  padding: 9px 8px 8px; }

.basic-features .feature .switch {
  float: right;
  margin-top: -2px; }

.basic-features .feature .loading {
  display: inline-block;
  position: relative;
  float: right;
  margin-right: 30px;
  bottom: 20px; }

.basic-features .feature .loading .load {
  left: 5% !important; }

.basic-features .feature svg {
  fill: var(--theme-infopop); }

.basic-features .feature .inline-success {
  display: none; }

.basic-features .feature .basic-feature-success-appear {
  opacity: 1;
  display: block; }

.basic-features .feature .basic-feature-success-appear.basic-feature-success-appear-active {
  opacity: 0.01;
  transition: opacity 1s ease-in 3s;
  display: block; }

.basic-features .feature .basic-feature-success-enter {
  opacity: 1;
  display: block; }

.basic-features .feature .basic-feature-success-enter.basic-feature-success-enter-active {
  opacity: 0.01;
  transition: opacity 1s ease-in 3s;
  display: block; }

.basic-features .view-more {
  margin-top: 15px; }

.applications {
  margin: 15px -10px 15px; }

.applications .application-holder {
  width: 20%;
  float: left;
  margin-top: 5px;
  padding: 0 10px; }

.applications .application-holder p {
  margin-top: 5px;
  text-align: center; }

@media screen and (max-width: 1240px) {
  .applications .application-holder {
    width: 33.3333%;
    float: left;
    margin-top: 5px;
    padding: 0 15px; }
  .applications .application-holder:nth-child(2n) {
    padding-right: 15px; }
  .applications .application-holder:nth-child(2n + 1) {
    padding-left: 15px; } }

@media (max-width: 1680px) {
  .applications .application-holder {
    width: 33.3333%; } }

.applications img {
  width: 180px;
  margin: 10px; }

.applications .app {
  padding: 3px 8px;
  margin-bottom: 15px;
  border-left: 5px solid white;
  margin-left: 8px; }

.applications .app h4 {
  margin: 0 0 5px; }

.applications .app h4 .btn {
  float: right;
  font-size: 12px;
  border-color: transparent;
  padding: 0; }

.applications .app p {
  margin: 0; }

.applications .app .no-btn {
  display: table;
  margin: 0 auto; }

.applications .app.one {
  border-color: #263744; }

.applications .app.two {
  border-color: #445463; }

.applications .app.three {
  border-color: #7d98aa; }

.applications .app.four {
  border-color: #1483c7; }

.applications .app.five {
  border-color: #c1d641; }

.applications .app.six {
  border-color: #fda328; }

.applications .app.seven {
  border-color: #b3b2b1; }

.applications .application {
  width: 100%;
  position: relative;
  background: var(--theme-button-two);
  border-radius: 10px; }

.applications .application:after {
  content: "";
  display: block;
  padding-bottom: 100%; }

.applications .application.archive {
  background: var(--theme-button-one); }

.applications .application.voice {
  background: var(--theme-accent); }

.applications .application.call {
  background: #ccc; }

.applications .application.other {
  background: var(--theme-link); }

.applications .application .icon {
  position: absolute;
  width: 100%;
  height: 100%; }

.applications .application .icon svg {
  position: absolute;
  top: 50%;
  left: 50%;
  margin-left: -30px;
  margin-top: -30px;
  fill: white; }

.hider {
  display: none; }

/* The switch */
.switch {
  position: relative;
  display: inline-block;
  width: 32px;
  height: 21px;
  vertical-align: middle; }

.switch input {
  opacity: 0; }

/* The slider */
.slider {
  position: absolute;
  cursor: pointer;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: #c8c3c4;
  -webkit-transition: 0.4s;
  transition: 0.4s; }

.slider:before {
  position: absolute;
  content: "";
  height: 15px;
  width: 15px;
  left: 3px;
  bottom: 3px;
  background-color: white;
  -webkit-transition: 0.4s;
  transition: 0.4s; }

input:checked + .slider {
  background-color: var(--theme-nuethree); }

input:focus + .slider {
  box-shadow: 0 0 1px var(--theme-nuethree); }

input:checked + .slider:before {
  -webkit-transform: translateX(11px);
  -ms-transform: translateX(11px);
  transform: translateX(11px); }

/* Rounded sliders */
.slider.round {
  border-radius: 34px; }

.slider.round:before {
  border-radius: 50%; }

.k-slider .k-slider-selection,
.k-button.k-primary,
.k-slider .k-draghandle,
.k-switch-on .k-switch-handle,
.k-action-buttons .k-primary.k-state-hvoer,
.k-action-buttons .k-primary:hover,
.k-button.k-primary.k-flat.k-state-hover,
.k-button.k-primary.k-flat:hover,
.k-button.k-primary.k-state-hover,
.k-button.k-primary:hover,
.k-dialog-buttongroup .k-primary.k-state-hvoer,
.k-dialog-buttongroup .k-primary:hover,
.k-slider .k-draghandle:hover,
.k-switch-on:hover .k-switch-handle {
  background-color: var(--theme-accent) !important;
  border-color: var(--theme-accent) !important; }

.k-button.k-primary,
.k-slider .k-draghandle,
.k-switch-on .k-switch-handle {
  background-image: none !important; }

.k-slider .k-draghandle:focus,
.k-slider.k-state-focused .k-draghandle {
  -webkit-box-shadow: none !important;
  box-shadow: none !important; }

.k-slider-horizontal {
  display: block !important;
  margin-left: 8px; }

.devices-card div.k-widget.k-grid div.k-grid-container div div:nth-child(1) table tbody tr td span span a {
  color: var(--theme-primary_button_text) !important; }

.devices-card .k-grid .k-grid-header .k-header .k-link {
  height: auto; }

.devices-card .k-grid .k-grid-header .k-header {
  white-space: normal; }

.devices-card .k-grid-content {
  -ms-overflow-style: none;
  overflow: auto; }

.services {
  min-width: 700px;
  overflow: hidden;
  /*td {
    white-space: nowrap;
  }*/ }

.services .clear-filter {
  padding: 0;
  cursor: pointer; }

.services .card-actions .search {
  margin: 2px 5px;
  float: left; }

.services .card-actions .search .form-control {
  width: 320px; }

@media (max-width: 1200px) {
  .services .card-actions .search .form-control {
    max-width: 240px; } }

@media (max-width: 992px) {
  .services .card-actions .search {
    float: none;
    margin: 10px 0;
    width: 100%; }
  .services .card-actions .search .form-control {
    width: 100%;
    max-width: 320px; } }

.services .card-content .table {
  border-top: none !important; }

.services .card-content .table th {
  cursor: pointer; }

.services .card-content .table th svg {
  fill: var(--theme-text);
  position: relative;
  top: 3px; }

.services .card-content .table tr {
  -webkit-transition: all 500ms ease;
  -moz-transition: all 500ms ease;
  -ms-transition: all 500ms ease;
  -o-transition: all 500ms ease;
  transition: all 500ms ease; }

.services .card-content .table tr:first-child:hover {
  background: white; }

.services .card-content .table tr:hover {
  background: RGBA(69, 88, 110, 0.05); }

.services .card-content .table td.actions .no-btn {
  white-space: nowrap; }

.services .card-content .table th.actions {
  padding-right: 18px;
  text-align: right; }

.services .card-content .table th.actions a {
  margin-left: 15px;
  position: relative;
  top: -2px; }

.services .card-content .table th.actions svg {
  fill: var(--theme-linktext);
  position: relative;
  top: 2px; }

.services .mac {
  white-space: nowrap;
  color: #44c466; }

.services .mac.down {
  color: #ff5346; }

.services .mac.down svg {
  fill: #ff5346; }

.services .primary {
  border: 1px solid var(--theme-accent);
  color: var(--theme-accent);
  font-size: 8px;
  padding: 1px 5px 1px;
  border-radius: 30px;
  position: relative;
  top: -2px;
  margin-left: 5px; }

.services .no-btn {
  display: inline-block;
  position: relative;
  top: -2px;
  margin: 0px 5px; }

.services .no-btn:hover,
.services .no-btn:focus {
  padding: 2px 6px; }

.services .btn,
.services .k-button {
  margin: 2px; }

.services .btn.icon,
.services .k-button.icon {
  min-width: inherit;
  padding: 8px 10px 6px; }

.services .btn.icon svg,
.services .k-button.icon svg {
  fill: var(--theme-linktext);
  position: relative;
  top: 1px; }

.locations {
  min-width: 700px;
  overflow: hidden; }

.locations .card-actions .search {
  margin: 2px 5px;
  float: left; }

.locations .card-actions .search .form-control {
  width: 320px; }

@media (max-width: 1200px) {
  .locations .card-actions .search .form-control {
    max-width: 240px; } }

@media (max-width: 992px) {
  .locations .card-actions .search {
    float: none;
    margin: 10px 0;
    width: 100%; }
  .locations .card-actions .search .form-control {
    width: 100%;
    max-width: 320px; } }

.locations .table {
  border-top: none !important; }

.locations .table th {
  cursor: pointer; }

.locations .table th svg {
  fill: var(--theme-text);
  position: relative;
  top: 3px; }

.locations .table tr {
  -webkit-transition: all 500ms ease;
  -moz-transition: all 500ms ease;
  -ms-transition: all 500ms ease;
  -o-transition: all 500ms ease;
  transition: all 500ms ease; }

.locations .table tr:first-child:hover,
.locations .table tr:first-child:focus {
  background: white; }

.locations .table tr:hover,
.locations .table tr:focus {
  background: RGBA(69, 88, 110, 0.05); }

.locations .btn,
.locations .k-button {
  margin: 2px; }

.locations .btn.icon,
.locations .k-button.icon {
  min-width: inherit;
  padding: 8px 10px 6px; }

.locations .btn.icon svg,
.locations .k-button.icon svg {
  fill: var(--theme-primary_button_text) !important;
  position: relative;
  top: 1px; }

.locations .no-btn {
  margin: 0; }

.locations .k-grid .k-grid-header .k-header .k-link {
  height: auto; }

.locations .k-grid .k-grid-header .k-header {
  white-space: normal; }

.locations .k-grid-content {
  -ms-overflow-style: none;
  overflow: auto; }

.enterprise-directory {
  min-width: 700px;
  overflow: hidden; }

.enterprise-directory .card-header {
  height: 100px; }

.enterprise-directory .card-header h1 {
  height: 85px; }

.enterprise-directory .card-actions .search {
  margin: 2px 5px;
  float: left; }

.enterprise-directory .card-actions .search .form-control {
  width: 320px; }

@media (max-width: 1200px) {
  .enterprise-directory .card-actions .search .form-control {
    max-width: 240px; } }

@media (max-width: 992px) {
  .enterprise-directory .card-actions .search {
    float: none;
    margin: 10px 0;
    width: 100%; }
  .enterprise-directory .card-actions .search .form-control {
    width: 100%;
    max-width: 320px; } }

.enterprise-directory .table {
  border-top: none !important; }

.enterprise-directory .table th {
  cursor: pointer; }

.enterprise-directory .table th svg {
  fill: var(--theme-text);
  position: relative;
  top: 3px; }

.enterprise-directory .table tr {
  -webkit-transition: all 500ms ease;
  -moz-transition: all 500ms ease;
  -ms-transition: all 500ms ease;
  -o-transition: all 500ms ease;
  transition: all 500ms ease; }

.enterprise-directory .table tr:first-child:hover,
.enterprise-directory .table tr:first-child:focus {
  background: white; }

.enterprise-directory .table tr:hover,
.enterprise-directory .table tr:focus {
  background: RGBA(69, 88, 110, 0.05); }

.enterprise-directory .table tbody tr td span button.k-button {
  min-width: 40px !important; }

.enterprise-directory .btn,
.enterprise-directory .k-button {
  min-width: 20px !important;
  margin: 2px; }

.enterprise-directory .btn.icon,
.enterprise-directory .k-button.icon {
  min-width: inherit;
  padding: 8px 10px 6px; }

.enterprise-directory .btn.icon svg,
.enterprise-directory .k-button.icon svg {
  fill: var(--theme-linktext) !important;
  position: relative;
  top: 1px; }

.enterprise-directory .no-btn {
  margin: 0; }

.enterprise-directory .k-grid .k-grid-header .k-header .k-link {
  height: auto; }

.enterprise-directory .k-grid .k-grid-header .k-header {
  white-space: normal; }

.enterprise-directory .k-grid-content {
  -ms-overflow-style: none;
  overflow: auto; }

.enterprise-contact-modal {
  width: 400px !important; }

.user-contact-modal {
  width: 600px !important; }

.emergency-call-notification-email-dialog {
  width: 700px;
  height: 600px; }

.emergency-call-notification-email-dialog table {
  width: 100%; }

.emergency-call-notification-email-dialog table tr td a.k-button {
  text-align: right; }

.emergency-call-notification-email-dialog table.CheckBoxList {
  width: 80%;
  max-height: 250px;
  overflow-y: scroll; }

.emergency-call-notification-email-dialog table.CheckBoxList th {
  border: 1px solid var(--theme-border);
  color: var(--theme-gridheader); }

.emergency-call-notification-email-dialog table.CheckBoxList td {
  border: 1px solid var(--theme-border);
  color: var(--theme-gridtext); }

.location-detail h2 {
  margin-bottom: 15px;
  border-bottom: 1px solid var(--theme-border) !important;
  padding: 4px 0 !important; }

.location-detail .table {
  margin-bottom: 60px; }

.location-detail .btn {
  font-size: 12px; }

.location-detail .btn.config,
.location-detail .btn.add {
  float: right;
  margin-top: -10px; }

.location-detail .btn.orange {
  float: right; }

.location-detail .btn.icon {
  min-width: inherit;
  padding: 8px 10px 6px;
  margin-left: 10px; }

.location-detail .btn.icon svg {
  fill: var(--theme-linktext) !important;
  position: relative;
  top: 1px; }

.location-detail .back {
  float: right;
  margin-right: 10px; }

.location-detail .placeholder {
  padding: 80px;
  text-align: center;
  background: #f9f9f9; }

.location-detail .codes {
  margin-bottom: 60px; }

.location-detail .codes .table {
  margin-bottom: 0; }

.location-detail .codes .code-admin td:first-child {
  width: 150px; }

@media screen and (min-width: 1360px) {
  .billing.container {
    width: 100%; } }

.billing .billing-info .card-content > div {
  margin-bottom: 15px; }

.billing .billing-info .btn,
.billing .billing-info .k-button {
  margin: 15px -5px 0; }

.billing .payment h2 {
  margin-bottom: 10px; }

.billing .payment h1 {
  margin: 15px 0;
  font-size: 42px;
  font-weight: 700;
  color: var(--theme-accent); }

.billing .payment .green {
  float: right;
  margin: -10px 0 0; }

.billing .statements .view-more {
  margin: 15px 0; }

.billing .balance-history .balance-history-action .filter {
  padding-right: 4px;
  float: left;
  width: 50%; }

.billing .balance-history .balance-history-action .filter:last-child {
  padding-right: 0; }

.billing .balance-history .balance-history-action:after {
  content: "";
  display: block;
  clear: both; }

.billing .balance-history .k-chart g path[fill="url(#kdef2)"] {
  fill: var(--theme-accent);
  stroke: var(--theme-accent); }

.greetings-modal .no-answer .form-group {
  background: #f9f9f9;
  padding: 10px;
  border-radius: 6px; }

.greetings-modal .no-answer label {
  font-weight: normal; }

.greetings-modal .no-answer .custom-control {
  margin-bottom: 15px; }

.greetings-modal .no-answer .custom-control .greeting-option-radio {
  width: 5%;
  float: left;
  padding-top: 25px; }

.greetings-modal .no-answer .custom-control .greeting-option {
  width: 95%;
  float: left; }

.greetings-modal .no-answer .custom-control .greeting-option input,
.greetings-modal .no-answer .custom-control .greeting-option select {
  margin-bottom: 5px;
  background: white; }

.greetings-modal .no-answer .custom-control:after {
  display: table;
  content: " ";
  clear: both; }

.collaborate-bridge .table-edit .half .form-group label {
  width: 100%; }

.collaborate-bridge .table-edit .half .form-group label a {
  float: right; }

.mfa-modal .mfa-modal__auth-app {
  margin-top: 16px; }

.mfa-modal .mfa-modal__auth-app,
.mfa-modal__save-email {
  display: inline-grid; }

.mfa-modal .mfa-modal__save-pin {
  margin: 25px auto 0;
  width: 180px; }

.mfa-modal .mfa-modal__auth-app img {
  margin: 0 auto; }

.payModal .cc-logos {
  position: absolute;
  top: 27px;
  right: 5px;
  width: 127px; }

.payModal .no-btn {
  margin: 30px auto;
  display: table; }

.payModal .k-datepicker .k-input {
  background: #f9f9f9;
  border-radius: 6px 0 0 0;
  border-bottom: 2px solid var(--theme-border); }

.payModal .k-datepicker .k-select {
  padding: 0 20px; }

.payModal .confirm-payment label,
.payModal .completed-payment label {
  width: 210px;
  font-weight: bold;
  text-align: right;
  padding-right: 15px; }

.payModal img {
  margin-bottom: 15px;
  width: 360px; }

.profile h2 {
  font-size: 24px;
  margin-bottom: 10px !important;
  padding-bottom: 5px;
  border-bottom: 1px solid var(--theme-border); }

.profile .profile-chunk {
  margin-bottom: 60px; }

.profile .company {
  font-weight: bold; }

.profile .company small {
  font-weight: normal; }

.profile .e911-update {
  margin: 10px 0 30px;
  display: inline-block; }

.profile .e911-update .time-to-validate {
  color: red;
  font-weight: bold; }

.profile .no-btn {
  display: inline-block;
  margin-top: 5px; }

.profile .green {
  border-color: var(--theme-button-one); }

.profile select {
  max-width: 540px; }

.settings-row {
  margin-bottom: 30px; }

.settings-row .setting {
  position: relative;
  border-bottom: 1px solid var(--theme-border);
  -webkit-transition: all 500ms ease;
  -moz-transition: all 500ms ease;
  -ms-transition: all 500ms ease;
  -o-transition: all 500ms ease;
  transition: all 500ms ease; }

.settings-row .setting .name {
  display: block;
  padding: 15px; }

.settings-row .setting .btn {
  float: right;
  min-width: inherit;
  padding: 8px 10px 6px;
  position: absolute;
  right: 3px;
  top: -1px; }

.settings-row .setting:hover,
.settings-row .setting:focus {
  background: RGBA(69, 88, 110, 0.05); }

.settings-row .setting:after {
  display: table;
  content: " ";
  clear: both; }

.set-btns .no-btn {
  margin: 0 3px; }

.enterpriseDirectoryModal,
.conferenceList {
  width: 720px; }

@media (max-width: 768px) {
  .enterpriseDirectoryModal,
  .conferenceList {
    width: inherit; } }

.enterpriseDirectoryModal .no-btn,
.conferenceList .no-btn {
  margin-right: 15px; }

.confirm-add-services-modals {
  width: 500px; }

.confirm-add-services-modals .k-panelbar {
  background-color: #f6f6f8;
  border-color: #f6f6f8;
  overflow: hidden; }

.confirm-add-services-modals .k-panelbar .k-group {
  color: var(--theme-text);
  background-color: white;
  padding: 15px; }

.confirm-add-services-modals .k-link.k-header.k-state-selected {
  color: var(--theme-accent) !important;
  font-weight: bold !important;
  background-color: white !important;
  border-bottom: 1px solid var(--theme-border);
  border-color: var(--theme-border) !important; }

.confirm-add-services-modals .k-panelbar > .k-item > .k-link.k-state-selected:not(.k-state-active) .k-icon,
.confirm-add-services-modals .k-panelbar > .k-item > .k-link.k-state-selected:not(.k-state-expanded) .k-icon {
  color: var(--theme-accent) !important; }

.confirm-add-services-modals .k-panelbar > .k-item > .k-link {
  color: var(--theme-text);
  background-color: #f6f6f8; }

.confirm-add-services-modals .k-panelbar > .k-item + .k-item {
  background-color: #f6f6f8;
  border-top-width: 0; }

.confirm-add-services-modals .k-animation-container {
  border: 1px solid #eee; }

.confirm-add-services-modals .k-panelbar .k-content {
  color: var(--theme-text);
  background-color: white;
  border-color: transparent; }

.confirm-add-services-modals .k-panelbar .k-content .form-control {
  background: #f6f6f8;
  background-color: #f6f6f8 !important; }

.confirm-add-services-modals .k-panelbar > .k-item > .k-link.k-state-hover,
.confirm-add-services-modals .k-panelbar > .k-item > .k-link:hover {
  color: var(--theme-accent);
  background-color: #f6f6f8; }

.confirm-add-services-modals {
  font-size: medium; }

.user-level-addon-modal {
  width: 60%;
  height: 80%; }

div.product-configuration-xsmall {
  width: 40% !important; }

div.product-configuration-small {
  width: 60% !important; }

div.product-configuration-medium {
  width: 75% !important; }

div.product-configuration-large {
  width: 92%;
  max-width: 92% !important; }

div.product-configuration-large div.modal-content {
  max-height: 80%;
  overflow: auto; }

div.product-configuration-large .k-picker-wrap.k-state-default {
  background: #f9f9f9;
  border-bottom: 2px solid var(--theme-border); }

div.product-configuration-large .k-panelbar .k-content .btns {
  margin: 2px auto 0; }

div.product-configuration-large .k-link.k-header.k-state-selected {
  border-radius: 0; }

div.product-configuration-large div#ProductConfigGrid div div.k-widget.k-grid div.k-grid-container div.k-gridcontent {
  overflow-y: auto !important; }

div.product-configuration-large div#ProductConfigGrid div table.k-grid-table tbody tr td div.popup-content {
  left: 0px !important;
  width: 450px !important;
  background-color: #f9f9f9; }

div.product-configuration-large div#ProductConfigGrid div table.DefaultSettings {
  width: 65%; }

div.product-configuration-large div#ProductConfigGrid div table.DefaultSettings tr {
  padding-bottom: 10px; }

div.product-configuration-large div#ProductConfigGrid div table.DefaultSettings tr td.DefaultTNLabelCol {
  padding-bottom: 10px; }

div.product-configuration-large div#ProductConfigGrid div table.DefaultSettings tr td.DefaultNextApply {
  padding-right: 20px; }

div.product-configuration-large div#ProductConfigGrid div table.DefaultSettings tr td.DefaultRCLabelCol {
  width: 25%;
  font-weight: bold;
  padding-right: 20px; }

div.product-configuration-large div#ProductConfigGrid div table#DefaultRateCenterSettings tr td.DefaultRCValueCol {
  width: 35%;
  padding-right: 20px; }

.macd-e911-address {
  width: 600px !important;
  text-align: center; }

.macd-e911-address div.modal-body {
  padding: 10px; }

.macd-e911-address table {
  text-align: left;
  width: 100%; }

.macd-e911-address table tr td div.table-edit div div.inline {
  margin-top: 20px; }

.macd-e911-address table tr td div.table-edit div.form-group div.reset {
  margin-bottom: 20px; }

.macd-e911-address table tr td div.table-edit div.form-group div.reset a {
  margin-right: 20px; }

.macd-ula-order-details {
  width: 850px; }

.macd-single-extension-order-details {
  width: 1000px; }

.macd-listing-order-details {
  width: 1200px; }

.macd-order-details {
  min-height: 400px;
  max-height: 700px; }

.macd-order-details div.modal-content {
  max-height: 685px;
  overflow: auto; }

.macd-order-details div.modal-content div.modal-header {
  padding: 5px; }

.macd-order-details div.modal-content div.modal-body {
  padding: 0px 5px; }

.macd-order-details div.modal-content div.modal-body div.k-grid.k-widget div.k-grid-header div.k-grid-header-wrap table thead tr th a {
  padding: 5px 10px; }

.macd-order-details div.modal-content div.modal-body div div div.k-grid-container div.k-grid-content.k-virtual-content {
  overflow-y: auto; }

.macd-order-details div.modal-content div.modal-body div div div.k-grid-container div div table tbody tr td {
  padding: 0px 10px;
  text-align: left; }

.macd-order-details div.modal-content div.modal-footer {
  padding: 5px 5px; }

div.macd div.tab-body div div div.k-grid div.k-grid-header-wrap table thead tr th {
  padding-left: 5px !important; }

div.macd div.tab-body div div div.k-grid div.k-grid-container div.k-virtual-content {
  overflow-y: auto; }

div.macd div.tab-body div div div.k-grid div.k-grid-container div.k-virtual-content div table tr td {
  padding-left: 0px !important; }

div.macd div.tab-body div div div.k-grid .k-grid-header .k-header {
  white-space: normal; }

div.macd div.tab-body div div div.k-grid .k-grid-header .k-header .k-link {
  height: auto; }

div.macd div.tab-body div div div.k-grid .k-grid-content {
  -ms-overflow-style: none;
  overflow: auto; }

.macd-pricebook-listings {
  width: 1000px;
  min-height: 400px;
  max-height: 700px; }

.macd-pricebook-listings div.modal-content {
  max-height: 685px;
  overflow: auto; }

.macd-pricebook-listings .k-picker-wrap.k-state-default {
  background: #f9f9f9;
  border-bottom: 2px solid var(--theme-border); }

.macd-pricebook-listings .k-panelbar .k-content .btns {
  margin: 2px auto 0; }

.macd-pricebook-listings .k-link.k-header.k-state-selected {
  border-radius: 0; }

.announcementsModal .k-panelbar .k-content .btns {
  margin: 2px auto 0; }

.dnisModal .modal-content .modal-body div .form-group div .k-grid .k-grid-container .k-virtual-content div .k-grid-table tbody tr td,
.announcementsModal .modal-content .modal-body div .form-group div .k-grid .k-grid-container .k-virtual-content div .k-grid-table tbody tr td,
.product-configuration .modal-content .modal-body div .form-group div .k-grid .k-grid-container .k-virtual-content div .k-grid-table tbody tr td {
  padding: "3px 1px"; }

.dnisModal .k-panelbar,
.announcementsModal .k-panelbar,
.product-configuration .k-panelbar {
  background-color: #f6f6f8;
  border-color: #f6f6f8;
  overflow: hidden; }

.dnisModal .k-panelbar .k-group,
.announcementsModal .k-panelbar .k-group,
.product-configuration .k-panelbar .k-group {
  color: var(--theme-text);
  background-color: white;
  padding: 15px; }

.dnisModal .k-link.k-header.k-state-selected,
.announcementsModal .k-link.k-header.k-state-selected,
.product-configuration .k-link.k-header.k-state-selected {
  color: var(--theme-accent) !important;
  font-weight: bold !important;
  background-color: white !important;
  border-bottom: 1px solid var(--theme-border);
  border-color: var(--theme-border) !important; }

.dnisModal .k-panelbar > .k-item > .k-link.k-state-selected:not(.k-state-active) .k-icon,
.dnisModal .k-panelbar > .k-item > .k-link.k-state-selected:not(.k-state-expanded) .k-icon,
.announcementsModal .k-panelbar > .k-item > .k-link.k-state-selected:not(.k-state-active) .k-icon,
.announcementsModal .k-panelbar > .k-item > .k-link.k-state-selected:not(.k-state-expanded) .k-icon,
.product-configuration .k-panelbar > .k-item > .k-link.k-state-selected:not(.k-state-active) .k-icon,
.product-configuration .k-panelbar > .k-item > .k-link.k-state-selected:not(.k-state-expanded) .k-icon {
  color: var(--theme-accent) !important; }

.dnisModal .k-panelbar > .k-item > .k-link,
.announcementsModal .k-panelbar > .k-item > .k-link,
.product-configuration .k-panelbar > .k-item > .k-link {
  color: var(--theme-text);
  background-color: #f6f6f8; }

.dnisModal .k-panelbar > .k-item + .k-item,
.announcementsModal .k-panelbar > .k-item + .k-item,
.product-configuration .k-panelbar > .k-item + .k-item {
  background-color: #f6f6f8;
  border-top-width: 0; }

.dnisModal .k-animation-container,
.announcementsModal .k-animation-container,
.product-configuration .k-animation-container {
  border: 1px solid #eee; }

.dnisModal .k-panelbar .k-content,
.announcementsModal .k-panelbar .k-content,
.product-configuration .k-panelbar .k-content {
  color: var(--theme-text);
  background-color: white;
  border-color: transparent; }

.dnisModal .k-panelbar .k-content .form-control,
.announcementsModal .k-panelbar .k-content .form-control,
.product-configuration .k-panelbar .k-content .form-control {
  background: #f6f6f8;
  background-color: #f6f6f8 !important; }

.dnisModal .k-panelbar > .k-item > .k-link.k-state-hover,
.dnisModal .k-panelbar > .k-item > .k-link:hover,
.announcementsModal .k-panelbar > .k-item > .k-link.k-state-hover,
.announcementsModal .k-panelbar > .k-item > .k-link:hover,
.product-configuration .k-panelbar > .k-item > .k-link.k-state-hover,
.product-configuration .k-panelbar > .k-item > .k-link:hover {
  color: var(--theme-accent);
  background-color: #f6f6f8; }

/*
.announcementsModal {
  .k-panelbar {
    background-color: $hidden_gray;
    border-color: $hidden_gray;
    border-radius: 0;
    overflow: hidden;
  }
  .k-panelbar .k-group {
      color: $text;
      background-color: white;
      padding: 15px 15px 35px;
  }
  .k-link.k-header.k-state-selected {
    color: $accent !important;
    font-weight: bold !important;
    background-color: white !important;
      border-bottom: 1px solid $border;
      border-color: $border !important;
      border-radius: 0;
  }
  .k-panelbar>.k-item>.k-link.k-state-selected:not(.k-state-active) .k-icon, .k-panelbar>.k-item>.k-link.k-state-selected:not(.k-state-expanded) .k-icon {
    color: $accent !important;
  }
  .k-animation-container {
      border-radius: 0;
  }
  .k-panelbar>.k-item>.k-link {
    color: $text;
    background-color: $hidden_gray;
  }
  .k-panelbar>.k-item+.k-item {
    background-color: $hidden_gray;
      border-top-width: 0;
      border-bottom: 1px solid $border;
    border-radius: 0;
  }
  .k-panelbar .k-content {
    color: $text;
      background-color: white;
      border-color: transparent;
      .form-control {
        background: $hidden_gray;
        background-color: $hidden_gray !important;
      }
  }
  .k-panelbar>.k-item>.k-link.k-state-hover, .k-panelbar>.k-item>.k-link:hover {
    color: $accent;
      background-color: $hidden_gray;
  }
}*/
.CallDispositionCodesModal .check input {
  margin-top: 5px; }

.statsModal .new-phone {
  position: relative;
  top: -15px; }

.statsModal .k-datetimepicker .k-picker-wrap .k-input {
  background: #f9f9f9;
  border-bottom: 2px solid var(--theme-border); }

.global-search-hint {
  position: absolute;
  padding: 8px 8px;
  left: -190px;
  color: var(--theme-link);
  font-size: 11px; }

.spinner {
  width: 70px;
  text-align: center; }

.spinner.saving {
  width: 80px; }

.load.spinner {
  position: absolute;
  top: 50%;
  left: 50%;
  margin: 0;
  margin-left: -35px; }

.load.spinner > div {
  width: 10px;
  height: 10px; }

.load.spinner.saving > div {
  width: 18px;
  height: 18px;
  margin-right: 8px; }

.load.spinner.relative {
  position: relative;
  margin-top: 50px; }

.load.spinner.fixed {
  position: fixed; }

.load.spinner.loading-link {
  position: relative;
  margin: -10px 0 -40px -40px; }

.navbar .global-search .load.spinner {
  position: absolute;
  left: -40px;
  top: 4px; }

.sidebar .global-search svg {
  top: 36px;
  left: 18px; }

.sidebar .global-search .load.spinner {
  position: absolute;
  top: 14px;
  left: 148px; }

.sidebar .global-search .load.spinner > div {
  width: 6px;
  height: 6px;
  margin: 1px; }

.load.spinner.call-activity-spinner {
  position: "absolute";
  margin-top: 100px; }

.spinner > div {
  width: 18px;
  height: 18px;
  background-color: var(--theme-link);
  border-radius: 100%;
  display: inline-block;
  -webkit-animation: sk-bouncedelay 1.4s infinite ease-in-out both;
  animation: sk-bouncedelay 1.4s infinite ease-in-out both; }

.spinner .bounce1 {
  -webkit-animation-delay: -0.32s;
  animation-delay: -0.32s; }

.spinner .bounce2 {
  -webkit-animation-delay: -0.16s;
  animation-delay: -0.16s; }

.adjacent-left.spinner {
  position: relative;
  height: 0;
  margin: 0px 140px 0 0;
  width: unset; }

.adjacent-left.spinner > div {
  width: 6px;
  height: 6px;
  margin: 0 0 4px 2px; }

.user-services .spinner {
  position: relative;
  height: 0;
  margin: -18px 132px 0px 0px;
  padding-bottom: 18px;
  width: unset; }

.user-services .spinner > div {
  width: 6px;
  height: 6px;
  margin: 0 0 4px 2px; }

@-webkit-keyframes sk-bouncedelay {
  0%,
  80%,
  100% {
    -webkit-transform: scale(0); }
  40% {
    -webkit-transform: scale(1); } }

@keyframes sk-bouncedelay {
  0%,
  80%,
  100% {
    -webkit-transform: scale(0);
    transform: scale(0); }
  40% {
    -webkit-transform: scale(1);
    transform: scale(1); }
  40% {
    -webkit-transform: scale(1);
    transform: scale(1); } }

.save-status {
  top: 50%;
  left: 0px;
  margin: 0;
  width: 100%;
  font-size: 1.6em; }

.save-status.success {
  text-align: center; }

.save-status.error {
  text-align: center; }

.iframe-settings {
  width: 100%;
  height: 100vh;
  border: none;
  overflow: hidden; }

/*.body {
  padding-top: 0;
  iframe {
    background: RGBA(69, 88, 110, .05);
      width: 100%;
      min-height: 100vh;
      padding-top: 60px;
      padding-left: 240px;
      padding-right: 15px;
      padding-bottom: 15px;
      .content {
      width: 100%;
      position: relative;
      background: white;
      float: left;
      padding: 20px;
        margin-top: -10px;
      border-radius: 10px;
      }
      .section_title {
        background: white;
      font-size: 24px;
      margin: 0 0 15px;
      padding-bottom: 5px;
      border-bottom: 1px solid $border;
        font-family: arial;
        color: $text;
      }
  }
}*/
.k-grid-content {
  overflow-y: auto !important; }

.k-grid-content div table tbody tr.k-master-row {
  color: var(--theme-gridtext); }

.date-range-select label {
  text-align: center;
  padding: 10px; }

.quick-view.card {
  min-height: 360px; }

.devices.card {
  min-height: 398px;
  overflow: hidden; }

.devices-card {
  padding-bottom: 160px; }

.devices-card .btns {
  display: table;
  margin: 0 auto; }

.devices-card .btns a {
  border-color: white;
  color: var(--theme-accent); }

.devices-card .tabs .no-btn {
  float: right;
  margin-right: 5px; }

.devices-card .card-actions .btn {
  margin-top: 2px; }

.devices-card .card-actions .search {
  margin: 2px 5px;
  float: left; }

.devices-card .card-actions .search .form-control {
  width: 320px; }

@media (max-width: 1200px) {
  .devices-card .card-actions .search .form-control {
    max-width: 240px; } }

@media (max-width: 992px) {
  .devices-card .card-actions .search {
    float: none;
    margin: 10px 0;
    width: 100%; }
  .devices-card .card-actions .search .form-control {
    width: 100%;
    max-width: 320px; } }

.devices-card .tn-list {
  padding: 0;
  list-style-type: none; }

.devices-card .tn-list li {
  border-bottom: 1px dotted var(--theme-nuethree);
  padding: 5px 2px 0px; }

.devices-card .tn-list li a {
  float: right;
  border-bottom: none; }

.devices-card .mac {
  white-space: nowrap;
  color: #44c466; }

.devices-card .mac.down {
  color: #ff5346; }

.devices-card .mac.down svg {
  fill: #ff5346; }

.devices-card .actions {
  text-align: right; }

.devices-card .icon.btn {
  padding: 10px 10px 4px;
  min-width: inherit; }

.devices-card .table {
  border: 1px solid black; }

.devices-card .table .btn {
  margin-top: 2px;
  margin-bottom: 2px; }

.device-history-modal {
  width: 90%;
  min-width: 750px;
  max-width: 1240px; }

.device-history-modal .card-content .k-grid-content {
  -ms-overflow-style: none;
  overflow: auto;
  max-height: 800px; }

.device-config-modal {
  width: 50%; }

.device-config-modal div.modal-content {
  min-height: 400px !important;
  position: absolute !important; }

.device-config-modal div.modal-content div.modal-body form.form-group table.table {
  table-layout: fixed;
  display: table; }

.device-model > div {
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
  cursor: default; }

.add-device-modal .no-btn {
  margin: 0 5px; }

.configure-devices-modal .infopop {
  position: relative;
  margin-left: 0; }

.configure-devices-modal .infopop > div {
  position: absolute;
  top: -6px;
  right: -10px; }

.configure-devices-modal .table {
  border-bottom: 0; }

.configure-devices-modal .table .btns .btn {
  font-size: 14px; }

.ip-trunk-modal {
  width: 800px; }

.ip-trunk-modal .custom-input-class {
  border-color: var(--theme-nav) !important; }

.ip-trunk-modal .column-container {
  padding: 10px 0;
  display: flex;
  justify-content: center; }

.ip-trunk-modal .column-container .column-wrapper {
  padding: 0px 25px; }

.ip-trunk-modal .column-container .column-wrapper .column-body {
  border: 1px solid var(--theme-nav); }

.ip-trunk-modal .column-container .column-wrapper .column-body div div.selectedItem {
  background-color: var(--theme-nav);
  color: var(--theme-nav_text); }

.ip-trunk-modal .column-container .modal-btn-wrapper a.tg-selections {
  position: relative;
  top: 75px;
  padding: 5px;
  display: block;
  width: 80px;
  text-align: center;
  color: var(--theme-nav_text) !important;
  background-color: var(--theme-nav) !important;
  border-radius: 10%; }

.manage-ip-trunk-page .card-content .header-wrapper {
  display: flex;
  margin-left: 20px; }

.manage-ip-trunk-page .card-content .header-wrapper .manage-ip-trunk-header {
  display: inline;
  position: relative;
  top: 20px;
  background-color: white; }

.manage-ip-trunk-page .card-content .header-wrapper .manage-ip-trunk-link {
  position: relative;
  top: 20px;
  padding-left: 10px;
  background-color: white; }

.manage-ip-trunk-page .card-content .manage-ip-trunk-wrapper {
  border: 1px solid lightgrey; }

.manage-ip-trunk-page .card-content .manage-ip-trunk-wrapper .form-footer {
  padding-top: 20px;
  display: inline; }

.manage-ip-trunk-page .card-content .manage-ip-trunk-wrapper .form-footer .inline-ip {
  display: none; }

.manage-ip-trunk-page .card-content .manage-ip-trunk-wrapper .form-footer .basic-feature-ip-appear {
  opacity: 1;
  display: inline;
  color: red;
  position: relative;
  bottom: 20px;
  left: 20px;
  margin-left: 15px; }

.manage-ip-trunk-page .card-content .manage-ip-trunk-wrapper .form-footer .basic-feature-ip-appear.basic-feature-ip-appear-active {
  opacity: 0.01;
  transition: opacity 1s ease-in 3s;
  display: inline;
  color: red;
  position: relative;
  bottom: 20px;
  left: 20px;
  margin-left: 15px; }

.manage-ip-trunk-page .card-content .manage-ip-trunk-wrapper .form-footer .basic-feature-ip-enter {
  opacity: 1;
  display: inline;
  color: red;
  position: relative;
  bottom: 20px;
  left: 20px;
  margin-left: 15px; }

.manage-ip-trunk-page .card-content .manage-ip-trunk-wrapper .form-footer .basic-feature-ip-enter.basic-feature-ip-enter-active {
  opacity: 0.01;
  transition: opacity 1s ease-in 3s;
  display: inline;
  color: red;
  position: relative;
  bottom: 20px;
  left: 20px;
  margin-left: 15px; }

.manage-ip-trunk-page .card-content .manage-ip-trunk-wrapper .form-footer .custom-form-submit {
  background-color: #57708f;
  color: white;
  padding: 5px 50px;
  border-radius: 6%;
  position: relative;
  bottom: 20px;
  left: 20px; }

.manage-ip-trunk-page .card-content .ip-form-container form {
  display: flex;
  flex-direction: row;
  flex-wrap: row wrap;
  justify-content: flex-start; }

.manage-ip-trunk-page .card-content .ip-form-container .column-one {
  padding-top: 20px;
  padding-bottom: 20px;
  padding-left: 20px; }

.manage-ip-trunk-page .card-content .ip-form-container .column-two {
  padding-top: 20px;
  padding-bottom: 20px;
  padding-left: 100px; }

@media (max-width: 1360px) {
  .manage-ip-trunk-page .card-content .ip-form-container .column-two {
    padding-left: 20px; } }

@media (max-width: 1199px) {
  .manage-ip-trunk-page .card-content .ip-form-container .column-two {
    padding-left: 0px; } }

@media (max-width: 1000px) {
  .manage-ip-trunk-page .card-content .ip-form-container .column-two {
    padding-left: 10px; } }

.manage-ip-trunk-page .card-content .ip-form-container .form-group-wrapper {
  padding-bottom: 10px; }

.manage-ip-trunk-page .card-content .ip-form-container .form-group-wrapper label {
  width: 200px; }

.manage-ip-trunk-page .card-content .ip-form-container .custom-input-class {
  font-size: 12px;
  height: 35px;
  width: 200px;
  display: "inline-block";
  font-weight: 300;
  padding: 10px;
  border: none;
  border-bottom: 2px solid var(--theme-border);
  outline: none;
  background: #f9f9f9;
  -webkit-box-shadow: none;
  box-shadow: none;
  -webkit-appearance: none;
  border-radius: 6px 6px 0 0;
  -webkit-border-radius: 6px 6px 0 0; }

.manage-ip-trunk-page .card-content .ip-form-container .custom-input-class:active,
.manage-ip-trunk-page .card-content .ip-form-container .custom-input-class:focus,
.manage-ip-trunk-page .card-content .ip-form-container .custom-input-class:hover {
  border-bottom: 2px solid var(--theme-linktext);
  -webkit-box-shadow: none;
  box-shadow: none;
  background-color: #f9f9f9; }

/*.macd-page {
  padding-bottom: 160px;
  .btns {
    display: table;
    margin: 0 auto;
    a {
      border-color: white;
      color: $accent;
    }
  }
  .save {
    display: none;
    background-color: $accent;
    border-top: 1px solid $border;
    position: fixed;
    bottom: 0;
      width: 100%;
      padding: 22px 30px 30px 240px;
      p {
        max-width: 540px;
        margin: 0 auto 15px;
      }
  }
}*/
@media print and (-ms-high-contrast: none), (-ms-high-contrast: active) {
  .sidebar {
    display: none; }
  .page {
    transform: rotate(-90deg);
    -webkit-transform: rotate(-90deg);
    -moz-transform: rotate(-90deg) !important;
    filter: progid:DXImageTransform.Microsoft.BasicImage(rotation=3) !important; }
  .macd {
    position: absolute !important;
    left: 10px;
    /* margin-left: -18% !important; */
    overflow: visible !important; }
  .macd div.noPrint {
    display: none; }
  .macd div.print div.k-grid div.k-grid-content {
    position: absolute !important; }
  .macd div.print div.k-grid div.k-grid-content div {
    position: relative !important;
    overflow: visible !important; }
  .macd div.print div.k-grid div.k-grid-content div table tbody tr {
    page-break-inside: avoid !important; }
  .macd div.print div.k-grid div.k-grid-content div table tbody tr td {
    font-size: 8pt;
    max-width: 140px;
    word-wrap: normal; }
  .macd div.print div.k-grid div.k-grid-header div.k-grid-header-wrap table thead {
    display: table-header-group; }
  .macd div.print div.k-grid div.k-grid-header div.k-grid-header-wrap table thead tr th a {
    font-weight: bold;
    font-size: 8pt;
    word-wrap: normal;
    overflow: visible; }
  div.footer-container {
    display: none; }
  /* IE10+ CSS styles go here */ }

@media print {
  @page {
    size: landscape;
    text-align: left;
    margin-left: 1cm;
    margin-right: 0.5cm; }
  .sidebar {
    display: none; }
  * {
    width: 100% !important;
    overflow: visible !important; }
  .k-grid .k-grid-container {
    display: block; }
  .k-virtual-content {
    overflow-y: visible; }
  div.container,
  div.full-card {
    margin-left: 20px !important; }
  .macd {
    position: absolute !important;
    left: 10px;
    overflow: visible !important; }
  .macd div.print div.k-grid div.k-grid-content {
    position: absolute !important; }
  .macd div.print div.k-grid div.k-grid-content div table tbody tr {
    page-break-inside: avoid !important; }
  .macd div.print div.k-grid div.k-grid-header div.k-grid-header-wrap table thead {
    display: table-header-group; }
  .macd div.print div.k-grid div.k-grid-header div.k-grid-header-wrap table thead tr th a {
    font-weight: bold;
    word-wrap: normal;
    overflow: visible; }
  .macd div.print div.k-grid div.k-grid-content div {
    position: relative !important;
    overflow: visible !important; }
  .macd div.print div.k-grid div.k-grid-content div table tbody tr td {
    font-size: 8pt;
    max-width: 140px;
    word-wrap: normal; }
  .macd div.noPrint {
    display: none; } }

.macd .tab-body div div div.search {
  margin-left: 40%; }

.macd .tab-body div div div.search .form-control {
  width: 500px; }

.macd .btns {
  display: table;
  margin: 30px auto; }

.macd .table .k-datetimepicker .k-picker-wrap .k-input,
.macd .table .k-timepicker .k-picker-wrap .k-input,
.macd .table .k-datepicker .k-picker-wrap .k-input {
  background: #f9f9f9;
  border-bottom: 2px solid var(--theme-border); }

.macd .macd-row {
  margin-bottom: 30px; }

.macd .macd-row .macd-item .form-group {
  border-bottom: 1px solid var(--theme-border);
  padding: 10px; }

.macd .macd-row .macd-item .form-group .form-control {
  display: inline;
  max-width: 320px;
  margin-left: 10px; }

.macd .macd-row .macd-item .no-btn {
  float: right;
  margin: 10px;
  position: relative;
  z-index: 2; }

.macd .macd-all .no-btn {
  margin: 0 3px; }

.macd .macd-users .table,
.macd .macd-users .table tr:first-child td {
  border-top: none !important; }

.macd .card-actions .btn,
.macd .card-actions .k-button {
  margin-top: 2px; }

.macd .card-actions .search {
  margin: 2px 5px;
  float: left; }

.macd .card-actions .search .form-control {
  width: 320px; }

@media (max-width: 1200px) {
  .macd .card-actions .search .form-control {
    max-width: 240px; } }

@media (max-width: 992px) {
  .macd .card-actions .search {
    float: none;
    margin: 10px 0;
    width: 100%; }
  .macd .card-actions .search .form-control {
    width: 100%;
    max-width: 320px; } }

.macd .macd-actions {
  float: right;
  padding: 15px 0px; }

.macd .macd-actions .no-btn {
  margin: 0 5px; }

.macd .macd-actions .no-btn:hover,
.macd .macd-actions .no-btn:focus {
  padding: 2px 0; }

.macd .block {
  background: #f9f9f9;
  padding: 8px 0; }

.macd .block label {
  margin-bottom: 0; }

.macd .block input {
  margin: 0 3px 0 15px; }

.macd .macd-search {
  max-width: 640px;
  margin: 60px auto; }

.macd .macd-search .search {
  margin-bottom: 30px; }

.macd .macd-search .macd-search-results {
  display: none;
  background: white;
  position: absolute;
  z-index: 100;
  margin-top: 15px;
  width: 240px;
  overflow: hidden;
  -webkit-box-shadow: 0px 5px 26px -2px var(--theme-link);
  -moz-box-shadow: 0px 5px 26px -2px var(--theme-link);
  -o-box-shadow: 0px 5px 26px -2px var(--theme-link);
  -ms-box-shadow: 0px 5px 26px -2px var(--theme-link);
  box-shadow: 0px 5px 26px -2px var(--theme-link);
  border-radius: 10px; }

.macd .macd-search .macd-search-results .search-item {
  padding: 10px; }

.macd .macd-search .macd-search-results .search-item:hover,
.macd .macd-search .macd-search-results .search-item:focus {
  background: RGBA(109, 139, 172, 0.1); }

.macd .macd-search .macd-search-results .search-item a {
  color: var(--theme-text); }

.macd .macd-search .macd-search-results .search-item span {
  color: var(--theme-linktext);
  float: right; }

.macd table.k-grid-table tbody tr td div.popup-content {
  left: 0px !important;
  width: 450px !important;
  background-color: #f9f9f9; }

.macd .message {
  margin-bottom: 15px;
  padding: 0 5px 5px; }

.macd .updated.color {
  background-color: RGBA(69, 88, 110, 0.05); }

.macd .updated.color .form-control {
  background: white; }

.macd .added-products {
  margin: 20px -10px 0; }

.macd .added-products:after {
  display: table;
  content: " ";
  clear: both; }

.macd .added-products .added-product {
  width: 25%;
  float: left;
  padding: 10px; }

@media (max-width: 1020px) {
  .macd .added-products .added-product {
    width: 50%; } }

@media (max-width: 768px) {
  .macd .added-products .added-product {
    width: 100%; } }

.macd .added-products .added-product .added-product-content {
  background: #f9f9f9;
  padding: 15px;
  border-radius: 10px; }

.macd .added-products .added-product .added-product-content:after {
  display: table;
  content: " ";
  clear: both; }

.macd .added-products .added-product h3 {
  font-size: 16px;
  margin-top: 0;
  margin-bottom: 10px;
  padding-bottom: 5px;
  border-bottom: 1px solid var(--theme-border);
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap; }

.macd .added-products .added-product h3 small {
  display: block;
  margin-top: 5px; }

.macd .added-products .added-product .form-group {
  width: 70px;
  float: left;
  margin-bottom: 0; }

.macd .added-products .added-product .form-group .form-control {
  background-color: white; }

.macd .added-products .added-product .remove {
  float: left;
  margin: 0 10px 0; }

.macd .added-products .added-product .actions {
  float: right;
  margin-top: 20px;
  text-align: center; }

.macd .added-products .added-product .actions a {
  display: block; }

.macd .added-products .added-product .actions .btn {
  margin: 0; }

.macd .added-products .added-product .actions .btn.configured {
  border-color: #44c466;
  color: #44c466 !important; }

.macd .added-products .added-product .actions .btn.configured svg {
  fill: #44c466; }

.macd td .btn.configured {
  border-color: #44c466;
  color: #44c466 !important; }

.macd td .btn.configured svg {
  fill: #44c466; }

.font-bold {
  font-weight: bold; }

.idle-timer {
  display: inline-block;
  color: var(--theme-lightnav);
  font-weight: bold; }

.device-status {
  margin-top: 8px;
  text-align: right; }

@media screen and (max-width: 992px) {
  .device-status {
    text-align: left; } }

.validation-success,
.update-success {
  font-weight: bold;
  color: #44c466; }

.fqdn-setup-modal label.existing {
  margin-top: 20px; }

.fqdn-setup-modal .existing-fqdns {
  max-height: 290px;
  overflow-y: scroll; }

.fqdns.table th,
.fqdns.table td {
  font-weight: unset;
  padding: 10px 5px; }

.footer-container {
  background: RGBA(69, 88, 110, 0.05);
  padding-bottom: 2px; }

.k-link div,
.use-ellipsis {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap; }

.tooltip-inner {
  background: var(--theme-nav_text);
  color: var(--theme-nav);
  border: 1px solid #c1c1c1; }

.k-header .k-link .k-i-sort-asc-sm,
.k-header .k-link .k-i-sort-desc-sm {
  position: absolute !important;
  left: -10px;
  bottom: 8px; }

.group-fqdn .modal-content {
  height: 440px; }

.validation-success,
.update-success {
  font-weight: bold;
  color: #44c466; }

.fqdns.table th,
.fqdns.table td {
  font-weight: unset;
  padding: 10px 5px; }

.k-animation-container {
  z-index: 1050 !important; }

.k-animation-container.k-animation-container-relative {
  position: relative; }

.documents-page div.card-content {
  font-family: Arial, Helvetica, sans-serif !important;
  font-size: 17px; }

.documents-page div.card-content .k-grid .k-grid-header .k-header {
  font-family: Arial, Helvetica, sans-serif !important;
  white-space: normal; }

.documents-page div.card-content .k-grid .k-grid-header .k-header .k-link {
  font-family: Arial, Helvetica, sans-serif !important;
  height: auto;
  font-size: 17px; }

.documents-page div.card-content .k-grid-content {
  -ms-overflow-style: none;
  overflow: auto; }

.documents-page div.card-content .k-grid-content div table.k-grid-table tbody tr td {
  font-size: 14px;
  font-family: "Open Sans", Arial, Helvetica, sans-serif !important; }

.documents-page div.card-content .k-grid-content div table.k-grid-table tbody tr td div b a {
  font-size: 14px;
  font-family: "Open Sans", Arial, Helvetica, sans-serif !important; }

.voice-user-settings .container {
  padding: 0;
  width: 100%; }

.voice-user-settings .well-header {
  background: var(--theme-lightnav);
  padding-left: 15px;
  margin-bottom: 20px; }

.voice-user-settings .control-label-bold {
  font-weight: bold; }

.voice-user-settings .form-group-seconds-input {
  width: 50px;
  display: inline-block;
  padding: 0 5px; }

.voice-user-settings label {
  font-size: 14px; }

.card-content .add-button {
  display: flex;
  justify-content: flex-end; }

.card-bg-color {
  background-color: #547bad; }

.card-text-color {
  color: white;
  font-size: 22px; }

.card-text-color-small {
  color: white;
  font-size: 16px; }

.ticketing-banner-head {
  padding-top: 10px;
  width: 70%;
  text-align: center; }

.card-header-banner-item {
  text-align: left; }

.line-banner-separator {
  height: 2px;
  color: gray;
  background-color: gray; }

.banner-messsage-alignment {
  width: 50%;
  margin: 0 auto; }

button.linkButton,
button.linkButton:focus {
  background: transparent !important;
  color: #337ab7;
  padding: 0% 2%;
  margin: 0% -4%;
  border: none;
  font-size: 1.2rem;
  outline: none;
  font-weight: 600;
  text-decoration: none; }

button.linkButton:hover,
button.linkButton:focus,
button.linkButton:focus-visible,
button.linkButton:active {
  color: #23527c;
  text-decoration: none; }

.mfa-chat-container {
  position: absolute;
  width: 400px;
  right: 50%;
  top: 34px; }

.mfa-chat-banner {
  background-color: #f2f2f2;
  border: 1px solid #ccc;
  border-radius: 5px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  padding: 10px;
  max-width: 300px;
  margin: 20px auto;
  z-index: 999;
  position: absolute; }

.mfa-chat-close {
  border: none;
  background-color: transparent;
  position: absolute;
  margin-bottom: 3px;
  left: 275px;
  cursor: pointer;
  z-index: 9999; }

.mfa-chat-content {
  position: relative; }

.mfa-chat-text {
  font-size: 14px;
  color: #333; }

.mfa-chat-banner::after {
  content: '';
  position: absolute;
  bottom: -10px;
  left: 50%;
  transform: translateX(-50%);
  border-width: 10px;
  border-style: solid;
  border-color: #f2f2f2 transparent transparent transparent; }
